import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/the-old-dark-house-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1932 gothic horror, The Old Dark House"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 10</h1>
                    <h2>The Old Dark House</h2>
                    <h3>June 26, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <h1>Transcript</h1>
                        <p><Link to="/episodes/the-old-dark-house">Back to the episode details</Link></p>
                        <div>
                        <p>Bryan! (00:05.9)
                        You&#39;re listening to the Brothers Grimm podcast, presented by the Cinema Suicide Film Society on Substack. I&#39;m Brian White, editor-in-chief of Cinema Suicide. I&#39;m joined by my co-host and actual brother, Dave White. Dave, how you doing?</p>
                        <p>Dave! (00:17.212)
                        Hey, how&#39;s it going?</p>
                        <p>Bryan! (00:23.337)
                        So, um...</p>
                        <p>We fragile grew up in neighborhood video stores. Steady diet of utter garbage that those shops provided us with continues unabated to this day. There&#39;s no one else I enjoy chopping it up with more about trashy movies and Dave. Just before we get into it, a little housekeeping. You wanna keep up with us between episodes, you can also find us on Blue Sky, Twitter, Instagram and Twitch at GrimBrosPod. We&#39;ve also got a sweet website now, brothersgrimpodcast.show. That&#39;s dot show.com takes you to another Brothers Grimm podcast.</p>
                        <p>You can listen to all our past shows there and read the transcripts. You can also contact us directly at grimbrospod.gmail.com with any questions, comments, or suggestions. Do let us know if there&#39;s a question that you love and would love to hear us give it the business. Now lastly, if you like what you hear, you can subscribe to us wherever you get your podcasts. And you&#39;ll be doing us a favor by leaving us a five star review on Apple podcasts. I want to get all that out of the way right at the top of the show. So we got a good one.</p>
                        <p>And we got a weird one. It&#39;s kind of outside of our usual scene. So let me give you a taste.</p>
                        <p>Bryan! (03:08.08)
                        That&#39;s it. We are doing the old dark house. This is not the original trailer. There very likely may have been a trailer for this movie, but the studios absolutely sucked at preserving their feature films. Most of them burst into flames because the nitrate medium or they ultimately succumb to vinegar syndrome. And that&#39;s where the stock ultimately decomposes to a vinegar scented brown soup. They definitely were not keeping trailer reels. So if there was ever a trailer for that movie, it is lost.</p>
                        <p>As usual, warning before we get rolling, we&#39;re gonna talk about this movie from beginning to end, so spoilers to follow. And also, lastly, literate, like just about that, yeah, yeah. So yeah, and also this is the second and final film of our Pride series for the month. And more so than our last episode about vamp, where Grace Jones&#39;s involvement, Keith Haring, made an otherwise forgettable movie memorable. This one is directed by James Whale, who&#39;s a gay artist whose work for Universal laid the very foundation upon which modern horror is built upon.</p>
                        <p>Dave! (03:42.241)
                        Yeah, you had a hundred years to see this movie, so. Ah.</p>
                        <p>Bryan! (04:07.076)
                        His contributions to the universal milieu are my favorites, and it&#39;s impossible to conceive of what the genre would look like without his contributions. So you&#39;d seen this one before.</p>
                        <p>Dave! (04:19.519)
                        I&#39;ve seen this movie several times. I friggin love it.</p>
                        <p>Bryan! (04:23.265)
                        I thought that I had and upon watching it, I don&#39;t think I ever actually watched this one. Like I said, James Wales, two major universals are my favorites. So that&#39;s Frankenstein and Bride of Frankenstein. Invisible Man, yeah.</p>
                        <p>Dave! (04:40.938)
                        He also did Invisible Man.</p>
                        <p>Bryan! (04:46.04)
                        But yeah, this movie really surprised the hell out of me because I really didn&#39;t know what to expect going into it. One thing that I was definitely not expecting was that it was funny because it is hilarious. So some facts, this was released in 1932. So some other movies released that year, we got Freaks, The Mummy, Murder&#39;s in the Room, or</p>
                        <p>White Zombie and Dr. X.</p>
                        <p>Dave! (05:18.243)
                        That&#39;s some forgettable crap there.</p>
                        <p>Bryan! (05:20.784)
                        really I mean with the expect with the exception of like freaks in the mummy and maybe what a white zombie uh murders in the room or like I definitely I have not I&#39;ve not seen that version um and I don&#39;t think I&#39;ve actually seen dr x either um but yeah the mummy is probably like my other if like apart from frankenstein like the mummy is probably my other favorite universal like yeah I like</p>
                        <p>Dave! (05:44.502)
                        Really? That&#39;s my least favorite of them.</p>
                        <p>Bryan! (05:47.04)
                        No shit, I love that one. I mean, it&#39;s really kind of hard to pick a favorite. Honestly, since I recently kind of watched the core of five, and I used to be really, really big into Dracula, but upon watching all of them, I find that Dracula is kind of toward the bottom for me now. A little bit, we&#39;ve got some really great shit in there, like Dwight Fry is awesome, Bela Lugosi, obviously, but there&#39;s...</p>
                        <p>Dave! (06:06.309)
                        It&#39;s a bit of a snore.</p>
                        <p>Bryan! (06:16.715)
                        It really is. Yeah.</p>
                        <p>Dave! (06:16.822)
                        It&#39;s a lot of language shots, just people staring at each other for like a solid 30 seconds.</p>
                        <p>Bryan! (06:22.816)
                        Yeah, yeah, but I really came around to the Wolfman and the Mummy really surprised me because it&#39;s just, it&#39;s a fucking weird movie. Like it&#39;s way stranger than I think I remembered it being.</p>
                        <p>Dave! (06:37.39)
                        And those sequels go off the rails pretty quickly. There&#39;s one that takes place in Massachusetts that&#39;s, I really enjoy it. It is not a good movie, but like, I think it&#39;s pretty fun.</p>
                        <p>Bryan! (06:40.43)
                        Yeah, yeah, most of them!</p>
                        <p>Well, here&#39;s a question. Are any of these movies like good movies? Like we...</p>
                        <p>Dave! (06:50.966)
                        Fuck yeah they are. Frankenstein is incredible. All the three that he made are really, really good movies.</p>
                        <p>Bryan! (06:58.undefined)
                        Yeah, well, I mean, I&#39;m talking like most of the stuff that we do on the show, I would not consider conventionally good. I love the hell out of all of it, but most of what we watch is fucking garbage. This is like the first time that I think that we&#39;re touching something that I want actually like, in a sort of more broader sense, is actually like a good movie. And yeah, like, Frank, it&#39;s crazy.</p>
                        <p>Dave! (07:08.11)
                        Oh yeah, no.</p>
                        <p>It&#39;s got a stacked cast. You know, by 1932 standards, this is a blockbuster event.</p>
                        <p>Bryan! (07:23.968)
                        Yeah. Yeah, which is really wild because by this point, we&#39;re only like 20 years out from the point that the film the film industry writ large moved from New Jersey to Hollywood and they&#39;re still figuring it out at this point like most of whatever. Yeah, like most of what these people are doing.</p>
                        <p>Dave! (07:38.646)
                        Yeah, none of these people were famous yet either. Or if they were, they were stage actors.</p>
                        <p>Bryan! (07:43.852)
                        That&#39;s it, like really is most of them came from that. And I think that&#39;s why a lot of these movies when you watch them, it&#39;s like, it really does kind of feel like you&#39;re watching a stage play because everybody who&#39;s making these movies came from the fucking stage or they came from vaudeville. And this movie&#39;s got tons of vaudeville comedy in it. So cast and crew, director, as we said, this is James Whale, who is, you know, if you&#39;re listening to this, he&#39;s a man who really doesn&#39;t need an introduction. He&#39;s one of the Silver Edge greats.</p>
                        <p>Dave! (08:10.774)
                        He&#39;s actually one of two British people in Hollywood who actually had power, apparently. Yeah, him and Charlie Chaplin. Which explains how this movie got made because he was able to just be like, hey, Universal, I want you to go. And like he went to Lemley and was like, I want you to go and get the rights to this book. And Lemley was like, what the fuck is this book?</p>
                        <p>Bryan! (08:17.9)
                        No shit, who was hither? Oh yeah, yeah that makes a lot of sense.</p>
                        <p>Dave! (08:34.55)
                        He was like, it doesn&#39;t matter. Just go get with the rights. He was like, all right, you&#39;re James Wales. So that&#39;s cool. And he was like, I want all these people. And he was like, who are these people? He was like, they&#39;re my friends from England. It was we&#39;re hiring them. They were like, OK, fine, I guess.</p>
                        <p>Bryan! (08:38.947)
                        Right.</p>
                        <p>Yeah. So, so he and Todd Browning were universals like one two punch for 1931 and 32. And they really, really defined the gothic vibes of what we kind of now we call universal monsters like in capital letters. His collaborations with Boris Karloff are keystone moments in the history of horror films starting with Frankenstein then nailing it all home with Bride of Frankenstein, which is a fucking delightful movie. It&#39;s just so goddamn good.</p>
                        <p>Dave! (09:15.37)
                        Which is a shame because apparently Boris Karloff did not like him. They didn&#39;t get along at all.</p>
                        <p>Bryan! (09:15.92)
                        Thanks for watching!</p>
                        <p>I know, but they work together quite a bit, which is crazy.</p>
                        <p>Dave! (09:23.57)
                        I don&#39;t think you had a lot of options if you were an actor.</p>
                        <p>Bryan! (09:26.608)
                        that maybe. Well also the way that the studio system worked at the time was you know the like a studio had a stable of people and I&#39;m sure that like in the sort of in the production areas it was all okay these are our horror movie people and these are our war movie people and these are the romance movie people and like they only work with this director and that director because Whale was very closely when he started working for Universal he was very closely</p>
                        <p>Bryan! (09:57.152)
                        And they were all over the place, but they very deliberately kind of pigeonholed him as a horror movie director, which we&#39;ve talked about this in the past, like when we talked about it in House and Sorority Row, like he&#39;s a guy who very consciously did not want to end up as a horror movie guy, but as it fucking happens, he&#39;s a really good horror movie guy. And the other stuff that he made kind of fizzled, which we&#39;ll talk. Yeah. So</p>
                        <p>Dave! (10:21.055)
                        Oh, it&#39;s totally forgettable.</p>
                        <p>Bryan! (10:25.644)
                        A lot of historians claim that he was openly gay. Kurt Harrington, who seems to know him best, incessantly wasn&#39;t so much as open as he just wasn&#39;t closeted. Like he wouldn&#39;t be open in the way that sort of we understand it today. It was just, he was a gay guy and nobody really seemed to make a big deal out of it.</p>
                        <p>A lot of hay is made nowadays that it seems like his sexuality kind of put the nails in the coffin for his career, but it really turns out that like he just directed a bunch of duds and then Universal went bankrupt and they got sold out to another company who installed a producer with him who really just didn&#39;t get him. And so they ran out his contract in a bunch of sort of shitty B movies and then after that like he just couldn&#39;t pick up work.</p>
                        <p>Dave! (11:10.463)
                        Well, and then you get the Hugh act too, and it&#39;s like, you know, nobody wanted to go along with it. I mean, I wouldn&#39;t discount the fact that his sexuality, it played a role, undoubtedly. It was not the singular reason why. And his kind of fizzled career is not the reason why he killed himself, necessarily. But it was part of it. It was all part of it.</p>
                        <p>Bryan! (11:12.791)
                        Yeah.</p>
                        <p>Mm. Right.</p>
                        <p>Bryan! (11:29.188)
                        Well, no, he had a bunch of strokes, didn&#39;t he? Yeah, yeah. But he was like, really, to get on the bad side of HUAC, you mostly just had to not be a hardline anti-communist. And a lot of, and particularly when we talk about this movie, most of the sort of commentary and critique is kind of lost on me because it seems like it was very much rooted.</p>
                        <p>in a time and place, but class consciousness was something that was very endemic to his entire personality because he was kind of a he wasn&#39;t he was like a middle-class guy who kind of scratched his way up.</p>
                        <p>Dave! (12:06.37)
                        Yeah, he grew up in a really like super industrial part of I think Northern England. And it was like everybody was working class there like it was it&#39;s like turn of the century Dickensian industrialists city. And so he had that sensibility of like working class guy.</p>
                        <p>Bryan! (12:11.769)
                        Yeah.</p>
                        <p>Yeah.</p>
                        <p>Bryan! (12:26.904)
                        Yeah, and it&#39;s something because Boris Karloff had it too. We&#39;re going to find out. Melvin Douglas, he was also, well, he was an American, wasn&#39;t he?</p>
                        <p>Dave! (12:36.39)
                        Uh, may, no, I don&#39;t think so. I think Gloria Stewart&#39;s, Gloria Stewart&#39;s the only American in this.</p>
                        <p>Bryan! (12:50.522)
                        And so like a lot of the people involved in this and this is something that&#39;s really kind of endemic to sort of European life is again that class consciousness because most of these you know like until like World War one most of these nations were like living under monarchies and so.</p>
                        <p>This is something that&#39;s completely alien to Americans, but to people, you know, particularly from England, these guys, it was sort of central to their life experience. And so when you bring that over and you start working in America and you start becoming very, very paranoid about communism, it&#39;s very easy to end up on blacklists. And that&#39;s kind of, that&#39;s a, I personally, I think that&#39;s a really big part of what happened to whale.</p>
                        <p>But also the studio system ate so many people alive and like he was another one of the casualties. Like Bela Bogosi also famously ended very poorly. Like it seems like the only people, like the little person of this stable who really kind of survived in any sort of way was Karloff.</p>
                        <p>Dave! (13:51.782)
                        And even that, like, you know, he ended up working in a shitload of like Corman movies. And he&#39;s incredible in all of them. Like, he was a really great actor. He survived. You know, they were all like working actors. Like, it was this or you went and did who knows what. Like, it wasn&#39;t like you retire and live off of your wealth because a lot of them didn&#39;t have any wealth.</p>
                        <p>Bryan! (13:58.776)
                        Mm-hmm.</p>
                        <p>Yeah.</p>
                        <p>Bryan! (14:10.868)
                        Oh, sure. I mean, well, that&#39;s the thing is this was really before they were like, there were movie stars, but again, the studio system made sure that like nobody was really getting paid. They were just employees of the system. So yeah, so speaking cast, speaking Karloff, Boris Karloff is, he&#39;s one of the main, like he&#39;s one of the headliners in the movie, but again, he&#39;s not really a major player in it. I personally, I think that like Frankenstein won</p>
                        <p>The Lemleys won Whale and Karloff like a lot of goodwill because it made so much fucking money. It&#39;s crazy that like you could just put.</p>
                        <p>Dave! (14:47.976)
                        I imagine though at that time though I bet they didn&#39;t they weren&#39;t willing to gamble on whether or not Karloff could actually act.</p>
                        <p>Bryan! (14:55.736)
                        That&#39;s probably it. I think that when you put when you make that kind of money They don&#39;t want to sort of they don&#39;t want to roll the dice on it. Like I really I remember this just recently There was a guy or in Peli. He made the original paranormal activity and he made it on something like $15,000 and it made something like hundreds of millions of dollars and so he never</p>
                        <p>Dave! (15:08.971)
                        Oh yeah.</p>
                        <p>That&#39;s the most profitable independent movie ever or something.</p>
                        <p>Bryan! (15:18.092)
                        Yeah, yeah, something like that. And it was like after that, like he never directed again because they wouldn&#39;t fucking let him. They would just put his name on. Maybe, I have no idea. Ha ha ha.</p>
                        <p>Dave! (15:23.658)
                        It also sounds like he&#39;s kind of a difficult person. Like it has like that Richard Stanley vibe to it. Not quite, I mean, Richard Stanley is a lunatic, but I don&#39;t think it&#39;s quite that bad.</p>
                        <p>Bryan! (15:35.2)
                        I love Richard Stanley. I think that guy&#39;s lunacy makes his movies good. But yeah, I definitely think it&#39;s a lot of that where he just In the event that like he wasn&#39;t a good actor, like by at least by the standards of the studio and he cost the money, it would have been much more disastrous. So this is I think that&#39;s why in this movie he just kind of goes like, you know,</p>
                        <p>Dave! (15:55.51)
                        Yeah, I mean, he does. He basically is redoing Frankenstein. And I imagine that is probably he was directed that way because I&#39;m you know, I think James Will had a lot of creative license and flexibility, but not universally. Like there was some shit that he just was not going to get away with.</p>
                        <p>Bryan! (15:58.5)
                        Frankenstein.</p>
                        <p>Mm-hmm.</p>
                        <p>Bryan! (16:14.912)
                        Yeah, well, sure. I mean, they gave him a lot like a long leash, but it&#39;s not like to do whatever he wanted. But.</p>
                        <p>Dave! (16:20.098)
                        So I&#39;m sure if Lemley was like, we want him to basically redo Frankenstein, we&#39;re also going to call back to it before the movie even starts. And it just seems like that&#39;s, you know, he was kind of locked in. But you know, he survives and ends up, you know, doing lots of great movies.</p>
                        <p>Bryan! (16:28.012)
                        Yeah.</p>
                        <p>Oh yeah, yeah. So yeah, so he, you know, he&#39;s just vital to horrors, Bela Lugosi, portrayed two of the big five Universal monsters, went on to a long and fruitful career in B-movies as well as Broadway. He went back and forth between Broadway and Universal and then Corman and like whoever would hire him. So like he stayed very busy. Yeah, that&#39;s right, that&#39;s right. He&#39;s in Targets. Yeah, so it&#39;s just like, he,</p>
                        <p>Dave! (16:54.758)
                        He&#39;s in the first Peter Bogdanovich movie, thanks to Roger Corman. He&#39;s in Targets.</p>
                        <p>Bryan! (17:05.076)
                        He seems to have been okay with just being a horror movie guy. And he was also.</p>
                        <p>Dave! (17:11.422)
                        He&#39;s why I think he&#39;s one of those guys kind of like Vincent Price where they appreciated they loved working and acting and they appreciated the people appreciated them.</p>
                        <p>Bryan! (17:19.724)
                        Yeah, it really must have really gone a long way because there was a period, basically, once the 40s hit and Universal kind of bottomed out, the monster movie thing was really supplanted by UFO movies and Invaders from Mars and that sort of thing. And so you didn&#39;t really see that. So it wasn&#39;t until the 60s when...</p>
                        <p>he was, this whole thing was kind of rediscovered and was really celebrated by like, you know, Forrest Ackerman and famous monsters of film land that he started to kind of pop up. Of course, he was, you know, he was acting throughout like these, these kind of dead periods, but like he really does seem to kind of surge back to the four in the sixties. Also, he was a founding member of the Screen Actors Guild, which was a major part of destroying the system and kind of like, yep, yep.</p>
                        <p>Dave! (18:07.73)
                        So is Gloria Stewart.</p>
                        <p>Bryan! (18:10.788)
                        So we also have Ernest Thessinger, and this is his first American picture. This kicks off his work with whale that would continue into the Bride of Frankenstein where he betrays Frankenstein&#39;s teacher, Dr. Pretorius. And he&#39;s a dude with a very interesting look that was made to play weirdos in horror movies. He... Ha ha ha. One of the things, he&#39;s so fucking funny. He&#39;s so funny.</p>
                        <p>Dave! (18:26.763)
                        He is a villainous homosexual.</p>
                        <p>That&#39;s what makes him awesome.</p>
                        <p>Bryan! (18:36.244)
                        One of the things that I found out about him while I was doing like a little bit of research for this stuff, apparently he is like, he was really, really big into like needle point and embroidery. Don&#39;t know why.</p>
                        <p>Dave! (18:46.134)
                        Guys, I read some quote from some other woman who was in a movie with him and she said something like, He didn&#39;t exactly have a masculine approach to anything.</p>
                        <p>Bryan! (18:56.597)
                        But he holds a title in some like prestigious embroidery organization in England. I thought that fact about him was really funny because as soon as he comes onto the screen in this movie, it&#39;s like, okay, I get it. I understand why he and Whale hit it off.</p>
                        <p>Dave! (19:12.066)
                        Yeah, they were very good friends.</p>
                        <p>Bryan! (19:14.84)
                        Yep, yep. And he&#39;s so fucking great. He&#39;s one of my favorite characters. And lastly, we got Melvin Douglas, who we mentioned before. He&#39;s a classic Hollywood leading man. And he acted right up until his death in 1982.</p>
                        <p>Dave! (19:26.034)
                        is Ileana Douglas&#39;s grandfather.</p>
                        <p>Bryan! (19:27.96)
                        That&#39;s true. And he appears in classics like this one in the Vampire Bat, but he&#39;s also in stuff like the Changeling in Ghost Story. Yep. That was his last picture. He died the next year. And he&#39;s a really interesting character in his own right because he married another actress named Helen Gehagen who then became like a, was voted into Congress, I think for California.</p>
                        <p>Dave! (19:34.658)
                        There&#39;s a ghost story.</p>
                        <p>Bryan! (19:53.94)
                        And they went out on this like tour of Europe and when they were in the in the in the early 30s Following World War one they were in the perfect spot to witness all of like Europe&#39;s worst fucking anti-semitism in the run-up to World War two And because of that the two of them became outspoken anti-fascists, which I think is</p>
                        <p>Dave! (20:12.932)
                        Yeah, he and Gloria Stewart, I think started like the Hollywood anti Nazi something or other. Yeah.</p>
                        <p>Bryan! (20:19.576)
                        the anti-Nazi League, yeah. And one of the things, yeah, one of the big hitches that made Whale fall out of favor with the studios was he did an adaptation of, I think it&#39;s called The Road Back or The Long Road Back, which is the sequel to All Quiet on the Western Front. And this was before World War II.</p>
                        <p>and Nazi Germany had agents all over California, all over Los Angeles and Hollywood, because it was a perfect fucking propaganda machine for him. When they found out that he was making this, all of the sort of movers and shakers who were aligned with the Nazi party objected very, very heavily to this production, because it made post-war Germany look really shitty.</p>
                        <p>Dave! (21:12.206)
                        It&#39;s, uh, that&#39;s because it was. Well, he also he served, he served in World War One, which is significant in this movie.</p>
                        <p>Bryan! (21:14.564)
                        Yeah, yeah. And like, so yeah, like he almost, he eventually got to make the movie as he wanted to make it and it suffered cuts after its release because of basically Germany&#39;s influence on Hollywood at the time. So fucking Nazis played a big role in the downfall of this guy as well.</p>
                        <p>Yeah, and he was.</p>
                        <p>Yeah, oh yeah, there&#39;s a couple of notes about it, a couple of mentions of it. He was actually a prisoner of war taken in Flanders. So he definitely got a piece of that. Stupid, sexy Flanders. Yep. So.</p>
                        <p>Dave! (21:50.35)
                        Stupid sexy Flanders.</p>
                        <p>It should be said there is a phenomenal book about James Whale called Gods and Monsters that everyone should read. And if you&#39;re too lazy to read, see the movie.</p>
                        <p>Bryan! (22:01.968)
                        Uh, wait, is it the is that that&#39;s the name of the movie? The book is called, I think, The Man Who Made Frankenstein? Something like that. But yeah, the movie is good. The movie is great. I&#39;ve never read the book, but I&#39;m aware of it. So some notes. This is based on the novel, Benighted, by an author named Jamie Priestley. It enters the public domain next year. The story was meant to be a commentary on post-World War I England, but honestly,</p>
                        <p>Dave! (22:09.021)
                        Something like that.</p>
                        <p>Bryan! (22:28.94)
                        Most of the commentary is lost on me. Class.</p>
                        <p>Dave! (22:30.986)
                        Even J.B. Priestley didn&#39;t like the story very much. Like he doesn&#39;t understand, he didn&#39;t understand why it was popular. And this is like a pretty loose adaptation, I think, but still, he was just kind of like, meh.</p>
                        <p>Bryan! (22:35.159)
                        Yeah.</p>
                        <p>No kidding.</p>
                        <p>Yeah, yeah. Yeah, but also much of the speech in the movie is delivered in the mid-Atlantic or trans-Atlantic accent, which is an accent that I fucking love.</p>
                        <p>Dave! (22:55.99)
                        And if you don&#39;t know what that is, think father. Rather. You can&#39;t.</p>
                        <p>Bryan! (22:58.836)
                        Yeah, yeah. Yeah. And at the end, I can&#39;t help it, but when the title card comes up at the end and says it&#39;s a universal picture, it&#39;s a universal picture. Yeah. But yeah, it&#39;s not exactly American. It&#39;s not exactly British. It&#39;s somewhere in between. And it is a learned accent. It&#39;s not a regional dialect. It&#39;s not a, it&#39;s not a.</p>
                        <p>Dave! (23:07.979)
                        Yeah.</p>
                        <p>Oh yeah, it is for theater. It exists so you sound classy.</p>
                        <p>Bryan! (23:24.504)
                        Yep, that&#39;s exactly what it was. And they taught it in schools and people learned to speak it so that they would sound classy. That was it.</p>
                        <p>Dave! (23:30.154)
                        Yeah, any- like, any time anyone is ever like, wait, isn&#39;t so and so British? And it&#39;s like, nope, you just think that because of the Mid-Atlantic accent.</p>
                        <p>Bryan! (23:35.18)
                        Nope. Yeah. I think I think I said that about Ava Gardner in the Sentinel. I was like, is she English or nope.</p>
                        <p>Dave! (23:39.082)
                        Yep. Yeah, any like female actor from the 30s 40s 50s, they&#39;re all going to do it.</p>
                        <p>Bryan! (23:49.324)
                        No. So this is a pre-code horror movie referring to the Hays Code, which became formally enforced in Hollywood 1935. If you&#39;re listening to this, you probably know what the Hays Code is. But if not, it&#39;s a sort of pre-MPAA rating system, but it was put in place by moral crusaders who were gigantic fucking babies about everything. Also, it&#39;s racist as fuck. So the court.</p>
                        <p>Dave! (23:53.515)
                        Also super important.</p>
                        <p>Oh yeah. Well, cause it all comes out of like weird Christian, you know, like pearl clutching essentially.</p>
                        <p>Bryan! (24:18.06)
                        Well, OK, so it was it. Yeah, it was it was created and disseminated among Hollywood players by a Catholic priest.</p>
                        <p>Dave! (24:26.942)
                        Yeah, it&#39;s because it&#39;s because regionally they were all these little groups like these little essentially Catholic groups that would decide what was and wasn&#39;t appropriate for their city or their town or whatever. And it got so the studios were becoming so inundated with all these demands and all these requests that they were like, fuck it, we can&#39;t we cannot accommodate everybody. We&#39;re going to have to sort of come up with our own centralized version of this.</p>
                        <p>And so they kind of, this is their answer to it. It&#39;s ridiculous.</p>
                        <p>Bryan! (24:57.112)
                        Yeah, so there&#39;s two, there&#39;s really, there&#39;s two parts of it. There&#39;s these sort of core tenets, and then there&#39;s a sort of broader, more nuanced version of it. So the core ones are there&#39;s no swearing, no nudity, either explicit or in silhouette, no drugs, no sex perversion, which is code for gay characters, no white slavery, which seems like a very specific complaint.</p>
                        <p>Dave! (25:16.19)
                        Mm hmm. That&#39;s right. And actually, you could have these things in your movie, but they had to be punished. So it had to be had to be shown as a kind of a moral failing or a bad thing, I guess.</p>
                        <p>Bryan! (25:23.488)
                        No miscegenation, which is literally from the list, which is...</p>
                        <p>Oh, that&#39;s right. Okay, so yeah, that&#39;s what the second part of the list is if you want to put these in your movie you have to then do this which is like a Big part of it was we had movies like Public Enemy and Scarface which Portrayed criminal life as exciting and you know thrilling, you know, because there&#39;s no fucking good guys in them those movies it&#39;s all bad guys and so people were like horrified by this which I</p>
                        <p>Fuck everybody. They&#39;ve been doing this forever.</p>
                        <p>Dave! (26:13.115)
                        yeah i mean it&#39;s basically have you ever read the uh charter for the massachusetts bay colony it&#39;s kind of like that but for movies</p>
                        <p>Bryan! (26:22.13)
                        No. The Comics Code Authority also basically, same thing with a few very comic book specific stuff to it, but it&#39;s basically this. So there&#39;s no references to venereal disease or contraception, no scenes of childbirth explicit or in silhouette, no naked, no naked children, which is pretty reasonable if you ask me.</p>
                        <p>Dave! (26:25.131)
                        It&#39;s the same thing. Yeah.</p>
                        <p>I mean, thank God for that one, though. Yeah.</p>
                        <p>Bryan! (26:47.092)
                        no ridicule of religion or clergy, and, oh my God, and no offense toward any nation. So, yeah, so created by a Catholic priest, which makes the no new children complaint seem very out of place. There&#39;s more to the code, but it deals with removing any sort of nuance in storytelling. Good guys had to be moral crusaders, bad guys had to be frothing mad lunatics who always got theirs in the end.</p>
                        <p>Dave! (26:49.902)
                        Well, James Whale&#39;s gonna give him what for.</p>
                        <p>Bryan! (27:14.765)
                        It&#39;s a contributing factor to the rise of the Western film genre, actually, because it was very easy to make these movies with no complaint.</p>
                        <p>Dave! (27:22.254)
                        Those movies are really pretty awful.</p>
                        <p>Bryan! (27:25.848)
                        For the most part, yeah. It&#39;s not until I think the Spaghetti&#39;s that I actually start giving a fuck about Westerns.</p>
                        <p>Dave! (27:30.414)
                        Because you know what? John Wayne can eat a big ol&#39; horse dick.</p>
                        <p>Bryan! (27:33.304)
                        You suck my dick. Fuck that guy. Yeah. So until 1968, this was considered one of the many, many lost films of Hollywood until the print was found and restored by Curtis Harrington.</p>
                        <p>Dave! (27:46.062)
                        Well, actually, do you know how they came up? So I watched a little on the Eureka release that I have. There&#39;s just a really short interview with Curtis Harrington. Do you know who Curtis Harrington is? Because he&#39;s actually pretty awesome.</p>
                        <p>Bryan! (27:56.144)
                        Uh, uh, yeah, well, I do just through virtue of reading about this before this, I&#39;d never really even heard of him. He was just, he was another director.</p>
                        <p>Dave! (28:06.478)
                        But he&#39;s done a lot of television in the 70s, he did the Killer Bees with Gloria Swanson. That movie is fucking weird, it is hilarious. But he also made Whoever Slew Auntie Rue and What&#39;s the Matter with Helen. And so they both have Shelley Winters in them. I think What&#39;s the Matter with Helen is also Debbie Reynolds. These movies are so fucking weird. They feel oddly bright, like he was very obviously influenced heavily by British.</p>
                        <p>Bryan! (28:11.98)
                        Oh yeah, yeah. Yeah, it is.</p>
                        <p>Yep, that&#39;s right. Yeah.</p>
                        <p>Dave! (28:35.446)
                        directors because they feel really British. He is not British, though. He&#39;s American. But he made a ton of stuff. They also feel very gay, which makes sense because he is gay. So he like went to London and happened to be there while James Whale was there. And someone was like, hey, you really like this guy. Do you want to meet him? And he was like, what? So they go and they&#39;re like, and someone&#39;s like, oh, well, they&#39;ve got this movie that no one&#39;s ever like you, maybe he, he had seen it, I think, but he was like,</p>
                        <p>Bryan! (28:54.625)
                        Yeah, yeah, yeah.</p>
                        <p>Dave! (29:03.142)
                        most people haven&#39;t seen it. Do you want to watch it with James Whale? And he&#39;s like, yes, I do very much want to watch it with James Whale. And so it turned out, he was like, why is it no, why is nobody seeing this? And it&#39;s because it had been sold to, so Universal sold it to Columbia. So William Castle could make the remake.</p>
                        <p>Bryan! (29:20.704)
                        So, well yeah, right, because there&#39;s a remake of this in 63 that Castle made. I&#39;ve not seen it, I don&#39;t really care to. After seeing this version, I don&#39;t fucking care about any other version.</p>
                        <p>Dave! (29:24.382)
                        And it is not good.</p>
                        <p>Yeah, it&#39;s not it&#39;s not good. And so it turned out that when they did that, it ended up being what they referred to as an inviable movie, which means that they couldn&#39;t do anything with it. And so it was sitting in their archive and it had started to degrade. And so Curtis Harrington, who had no like no clout at all, really, he just pestered them so for so long to get this thing preserved. And like the steps they had to go through.</p>
                        <p>Bryan! (29:53.412)
                        Like, get this fucking guy out of here!</p>
                        <p>Dave! (29:56.53)
                        And yeah, and he just kept going back and like he would track it down. He paid for all this shit. Like he put in so much effort just we have this movie because of Curtis Herring.</p>
                        <p>Bryan! (30:03.298)
                        Right.</p>
                        <p>Yeah, yeah. And from what I understand is it wasn&#39;t that he found a complete version of this. He found it in bits and pieces. And you can actually, yeah. Yeah, you can see that both in the Cohen and the Eureka releases of this, where there&#39;s little jump cuts, where very clearly there&#39;s a few frames missing here and there. But this guy pieced it back together.</p>
                        <p>Dave! (30:12.15)
                        Yeah, he found pieces of it and took it to Kodak and was like, can you please fix this? They were, they did very slowly.</p>
                        <p>Yeah, the Cohen is the company that did the, they did the new transfer or the 4K transfer and they did a fucking amazing job.</p>
                        <p>Bryan! (30:37.248)
                        Yeah. Yeah, oh yeah, that&#39;s the one that I watched. And it&#39;s so good. But yeah, I think Curtis Harrington, I think we have him really centrally. He&#39;s such a small figure in this, but I think that there wouldn&#39;t be movies that hang so heavily on Arch and Camp. Like, I don&#39;t like as much as John Waters credits like, hold me.</p>
                        <p>Dave! (31:04.719)
                        Oh, like the Couch Hour Brothers and shit like that.</p>
                        <p>Bryan! (31:06.544)
                        Kuchar Brothers and like that sort of thing. Like I think that a lot of a lot of Harrington&#39;s influence also plays into this because his movies are very, very campy and like in particularly and you can see its influence on the movie Rocky Horror as well. Because it&#39;s like not only is that movie just goofy as hell and it&#39;s campy and it&#39;s arch but it&#39;s almost it&#39;s almost like beat by beat this movie.</p>
                        <p>Dave! (31:32.029)
                        Oh, yeah, it&#39;s an obvious homage.</p>
                        <p>Bryan! (31:34.124)
                        Yeah, yeah. But yeah, like one of the things that I found when I was actually kind of, when I came up with this is I found that Martin Scorsese has some sort of like film preservation Institute that he, I don&#39;t know, he has his name on, he probably pays for, but they have some like estimate that like 50% of movies made before 1950 have been lost forever. And</p>
                        <p>Dave! (31:59.702)
                        I think it&#39;s higher than that.</p>
                        <p>Bryan! (32:01.848)
                        90% of movies made before 1929 have also been lost. Yeah, yeah, which is just such a depressing. It was, yeah, it was nitrate stock, which just you couldn&#39;t even like have, you couldn&#39;t even get it near flame. Like, you know, famously at the end of Inglourious Bastards, like it&#39;s central to killing Hitler. But, um.</p>
                        <p>Dave! (32:04.982)
                        That&#39;s the one I&#39;m thinking of. Yeah, it&#39;s just because they all degraded or went up in flames.</p>
                        <p>They also, they would recycle it a lot too.</p>
                        <p>Bryan! (32:25.78)
                        Yeah, yeah. So the film opens on a dark and stormy night as so many dark house movies do. And that&#39;s the thing, before this, I never realized that there was a name for this, but it&#39;s a whole sub genre of horror called the Dark House Horror Movie. And there&#39;s a shitload of them.</p>
                        <p>Dave! (32:40.04)
                        Yeah. Oh, and yours, I sent you the producer&#39;s note that the Eureka! release opens with. Like, before the movie even starts, it opens with this note that&#39;s like the same person who plays the mad butler in this film is the actor who portrays Frankenstein&#39;s monster. And I really cannot find a reason, like a concrete explanation for why this is, why they put this up there.</p>
                        <p>Bryan! (32:47.052)
                        Yes. Right.</p>
                        <p>Yeah, the Cohen version does not have that card, but we talked about it a little bit. Honestly, I think it&#39;s just that news didn&#39;t move around the way that it does now. And so like it wasn&#39;t, if you watched Frankenstein and you didn&#39;t know it was Boris Karloff in that movie and this movie because he&#39;s so heavily made up in Frankenstein, you might not know that it&#39;s the same guy. So like, hey, if you liked Frankenstein, that guy&#39;s in this movie. So, you know.</p>
                        <p>But yeah, so our main characters, Philip and Margaret Waverton are traveling through the Welsh mountains on their way to Shrewsbury with their friend Roger Pandrell, who&#39;s my favorite character.</p>
                        <p>Dave! (33:40.246)
                        This is the fucking... this is the welshest... welshest movie ever.</p>
                        <p>Bryan! (33:44.372)
                        They keep saying Shrewsbury over and over. And I mean, it&#39;s like, it is like a fucking torrential, it&#39;s harder than a torrential downpour. There is, it is ridiculous how hard this weather is. They&#39;re driving like an old rag top through this like rough.</p>
                        <p>Dave! (33:59.874)
                        that is covered, absolutely covered in like mud and debris. Like it&#39;s like they drove through escape from New York on their way to Shrewsbury.</p>
                        <p>Bryan! (34:04.781)
                        Yeah, yeah!</p>
                        <p>Yeah. It&#39;s rough mountain terrain, which must be a pain in the ass since this is back in the day when operating an automobile was like driving, even under optimal conditions, was like driving a tank. They&#39;re like crashing into shit. They drive through like feet deep water while Penderel sings and fucks around in the backseat.</p>
                        <p>Dave! (34:29.194)
                        And apparently they had to do this for like hours. Like they drove a certain number of miles just on the studio lot back and forth, like in these shitty conditions. Because the thing that we have not said is that James Whale was a bit of a stickler when he came to directing.</p>
                        <p>Bryan! (34:31.887)
                        Oh my god.</p>
                        <p>There is there&#39;s all sorts of weird little cuts in this movie to like to show you certain details like right in this scene as well. Philip is like him and him and Margaret are just chatting at each other they are so just bitching at one another about the.</p>
                        <p>Dave! (34:59.95)
                        But not in a way that&#39;s mean, it&#39;s in a very like, it&#39;s got that Dashiell Hammett sort of like really crisp crackling dialogue. It&#39;s real Nick and Nora Charles, like kind of quick, quick jibes, but it&#39;s all done in kind of like, they&#39;re overwhelmed and annoyed, but they don&#39;t dislike each other.</p>
                        <p>Bryan! (35:06.832)
                        Yeah, yeah, it&#39;s... .. Yeah.</p>
                        <p>They&#39;re being sarcastic.</p>
                        <p>No, no, they&#39;re just they&#39;re just mutually pissed off at this at the situation.</p>
                        <p>Dave! (35:21.15)
                        And meanwhile, their drunk friend in the back is just like, that&#39;s, I&#39;m the one in the back that&#39;s just being sassy. It&#39;s just like, well, I&#39;ll drive.</p>
                        <p>Bryan! (35:28.282)
                        Yep. Yeah, he&#39;s the he&#39;s the he&#39;s the sassy third wheel. But there&#39;s a scene where like one of these Philip is just bitching about everything that&#39;s happening to him at one point he says like, and I certainly love all this ice cold water that&#39;s trickling down the back of my neck and then it cuts to like a close up of ice cold water trickling down the back of his neck. Yep.</p>
                        <p>And then Margaret tries to consult the map, which is just this like soaked through thing. It looks like a humongous napkin that&#39;s just been soaked in tea. And she calls it a puddle. And then Philip remarks, seems to represent this country pretty well. Everything is under water. To which I say, suck it, whales. Yep.</p>
                        <p>Dave! (36:06.642)
                        Yep, you&#39;re really sticking it to whales, aren&#39;t you? You&#39;re Melvin Douglas.</p>
                        <p>Bryan! (36:09.888)
                        Yep, and that&#39;s right there. That&#39;s right there on the haze code. No offense toward any nation in your face whales Yep So, you know, they drive through and I mean this thing is like getting stuck and it&#39;s like sliding around the fucking mud And a landslide very nearly pushes them off the road when finally they come up It&#39;s very it&#39;s very language</p>
                        <p>Dave! (36:16.214)
                        That is strike one of 1,000 strikes.</p>
                        <p>And I love that landslide just kind of comes out laterally from the mountain. It&#39;s like it&#39;s got four dudes behind it pushing it in.</p>
                        <p>Bryan! (36:40.696)
                        It&#39;s very languid and mechanical the way that it happens. But this is when they come upon the old dark house. And this is where you get your first big time Rocky Horror vibes. This is the there&#39;s a light moment. And it&#39;s this humongous medieval stone facade. Yep, all shut on a back lot. Yep. They knock on the door and it slowly parts, revealing Boris Karloff as TORGO!</p>
                        <p>Dave! (36:57.598)
                        And it is indeed just a facade. This whole movie was shot on a soundstage.</p>
                        <p>Yeah. Mmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmmm</p>
                        <p>Bryan! (37:09.58)
                        Yep, I mean, I mean, he Morgan, I mean, Morgan, but yeah, he does that he opens the door and he kind of like, they&#39;re like, can we come in and they&#39;re and he&#39;s just like, and then he closes the door in their face.</p>
                        <p>Dave! (37:22.318)
                        So apparently they didn&#39;t, I was listening to the, Glory Stewart did the commentary on the one I was watching and they hadn&#39;t seen what he was gonna look like yet. And you know, as far as Jack Pierce makeup goes, this one&#39;s pretty weak, at least for Karloff. But she was like, when we opened the door, he was really scary looking because he&#39;s really, he looks big, he&#39;s all mangled and he&#39;s just so intense. He does so much without ever speaking.</p>
                        <p>Bryan! (37:35.638)
                        Yeah, yeah.</p>
                        <p>That&#39;s the thing.</p>
                        <p>Bryan! (37:47.704)
                        That&#39;s the thing, I got notes in here about this a little further on, but I don&#39;t know if we might as well fucking talk about it now. Karloff always gives the impression that he was a really, really big man. He&#39;s not, he&#39;s only about six feet tall, but like platform shoes and costuming were doing a lot. He has a very big, like imposing presence in these movies.</p>
                        <p>Dave! (37:59.638)
                        And he&#39;s not.</p>
                        <p>Bryan! (38:12.7)
                        Especially this one and like the way that he shot like whale shoots him very deliberately from like low angles To make him seem bigger and like when he&#39;s in scenes with some of the women like there&#39;s much shorter than he is So he does seem to be very big, but he was like about six feet tall but it Yeah, but like a couple of years later he&#39;s in the black cat with Karloff or no with Lugosi</p>
                        <p>Dave! (38:16.862)
                        Yeah, he shoots him lower.</p>
                        <p>It&#39;s just the way he uses his body.</p>
                        <p>Bryan! (38:39.797)
                        And like he there are scenes where he&#39;s like shirtless and stuff and he&#39;s kind of a small guy like he&#39;s really it&#39;s really kind of amazing to see the transformation</p>
                        <p>Dave! (38:48.838)
                        And he was apparently like a very, very nice person. Like this, it was it was really hard for him to portray this character because he has to be menacing and he doesn&#39;t like to be menacing. So he has to like chase her around. It&#39;s like it upset it upset it.</p>
                        <p>Bryan! (38:52.057)
                        That&#39;s what.</p>
                        <p>I know. Yeah, he really gets rough with these girls and it must have been. Yeah, but yeah, like if you watch The Raven, Vincent Price is Vincent Price is noticeably taller than he is. So yeah, 6&#39;4 or 6&#39;5, something like that. So yeah, it&#39;s really kind of like the transformation of this man is amazing. Let&#39;s just watch it. Fuck it, guys. Podcast over. We&#39;re going to re-watch The Raven.</p>
                        <p>Dave! (39:14.75)
                        Inventor prices like six five</p>
                        <p>Also, just watch, let&#39;s just watch The Raven. I fucking love that movie.</p>
                        <p>Bryan! (39:29.62)
                        So, yeah, Karloff mumbles something unintelligible at them and he closes the door. But then a gong sounds and he opens it again, violently ushering them in. And this is where we enter Ernest Thessinger as Horace Femme. He&#39;s the man of the house. And he&#39;s a what? That name, it probably came from the book, but...</p>
                        <p>Dave! (39:48.162)
                        Which is such, the fuck, that name is so great. And it&#39;s in the book, but you know that Whale was like, fuck yes.</p>
                        <p>Bryan! (39:57.072)
                        I know I how I know I know when I when I was like did you make that up I had to find this book because it&#39;s a very. But again, and as we go on a little further on well actually the kind of the big third act turn there&#39;s a moment where that kind of comes into clarity with the like with Sir Roderick right. So this is no we will get there because that&#39;s like my favorite part of the whole.</p>
                        <p>Dave! (40:22.083)
                        Don&#39;t spoil the reveal.</p>
                        <p>Bryan! (40:27.505)
                        So this is Horace Fabb, he&#39;s the man of the house, and he&#39;s a wild looking character.</p>
                        <p>Dave! (40:30.934)
                        Now, his makeup looks really great because he looks skeletal.</p>
                        <p>Bryan! (40:34.944)
                        He has very skeletal, like he, like, you can look him up and he is very handsome young. Yeah, yeah, very handsome young man who sort of like aged into this look that just served very well in horror movies. He&#39;s got these very skeletal features. He&#39;s super like pointy ass eyebrows. And he, as they&#39;re all sort of like introducing himself, he uses the phrase charmed, I&#39;m sure.</p>
                        <p>Dave! (40:40.394)
                        It has very fine, very fine features.</p>
                        <p>Bryan! (41:01.944)
                        And I have heard that phrase a million times and I gotta tell you, I have no idea what it means. It sounds so passively, aggressively shitty to me.</p>
                        <p>Dave! (41:11.245)
                        Mm hmm. I think it means I am certain I&#39;m very happy to meet you.</p>
                        <p>Bryan! (41:15.084)
                        Yeah, yeah, yeah. It&#39;s still roundabout way of saying.</p>
                        <p>Dave! (41:17.714)
                        Except so the thing the thing about this movie and the reason I wanted to do it and I&#39;ll skip the excuse for not doing the other thing but. It it everything that we recognize as kind of queer humor or particularly gay humor gay male humor it comes from this, it is this era. And this movie there is an ineffable queer humor to this, so I will give a quick break here in that I was reading an essay.</p>
                        <p>in the came with the blu-ray Where it&#39;s some film critic. He&#39;s a British film critic. I can&#39;t remember his name He said that when this came out it did okay It was you know, it was a kind of moderate hit. I mean there were like six movies. So I mean How could it not be but it was like the guy says it was probably too Sophisticated for American audiences or who wanted just a horrible like they wanted some scares and I thought is it too sophisticated? Or is it just too British?</p>
                        <p>Like it is a very British movie, but it&#39;s also a very gay movie. And if you did not understand that humor, and most people didn&#39;t, you had no insight into that world. All of that would have been lost on everybody. And it is this is so it is so entrenched in this that you cannot separate it.</p>
                        <p>Bryan! (42:15.873)
                        Ehh</p>
                        <p>Yeah, so the way that this worked, like you&#39;d said this earlier, he worked very hard to get Lemley to sort of put this into production. Whereas with Frankenstein and Bride of Frankenstein in particular, those were movies that were kind of foisted upon him. Like when he came into the system, he went into work for Universal, they were like, here&#39;s.</p>
                        <p>Bryan! (42:58.197)
                        And he looked at all of them and was like I guess I&#39;ll do Frankenstein because like nothing else really looked good He was very conscious about becoming Yeah Because yeah like before this all he had done was war movies And he was like I don&#39;t want to be a war movie guy either because really what he wanted to do was a movie called showboat Which it was like his passion project that completely fucking fizzled on him but</p>
                        <p>Dave! (43:03.626)
                        I guess I&#39;ll do this Waterloo movie. Eugh.</p>
                        <p>Bryan! (43:23.264)
                        Like I said, like these horror movies were the ones that he did very well, and the ones that he didn&#39;t really care to do were the ones that he did his best on and were best received. This was one of the ones that he personally wanted to do, and it had a real sort of lukewarm reception, which</p>
                        <p>Dave! (43:41.47)
                        It should also be said that by this time, words like gay and queer were already incorporated into sort of gay male lexicon. So when they show up in these movies, it&#39;s not necessarily coincidental. There is a lot of double entendre, especially in this movie.</p>
                        <p>Bryan! (43:50.665)
                        Oh no shit, I did not notice.</p>
                        <p>Oh, right, cuz there&#39;s one line a little later on where I think</p>
                        <p>Dave! (44:03.447)
                        We&#39;ll get to that scene because that is a strange part of the movie.</p>
                        <p>Bryan! (44:07.412)
                        Yeah, okay, so yeah, I&#39;ll save that because I&#39;m already kind of off the rails. But so he&#39;s sort of the riffraff of this movie. The very first thing he does, and this movie is loaded with all these weird non-sequitors where like in this particular moment, just as soon as he&#39;s introduced to these people, the first thing he does is he grabs a bouquet of flowers and he explains that his sister was about to arrange them before throwing them in the fireplace.</p>
                        <p>Dave! (44:37.018)
                        But he&#39;s gonna hold on to that vase because he&#39;s gonna need it to punctuate a sentence</p>
                        <p>Bryan! (44:38.816)
                        And he holds the... Yeah, he holds the vase. And I don&#39;t... I can&#39;t remember off the top. I just fucking watched this movie too, but he&#39;s shocked all of a sudden by something somebody says and he drops it and it shatters.</p>
                        <p>Dave! (44:55.722)
                        And this is very theatrical. That is a stage moment right there.</p>
                        <p>Bryan! (44:59.08)
                        Oh yeah, it was supposed to be shocking. You know, it&#39;s a loud sound that hits very suddenly. But he explains that, yeah, so he throws the things in the fire. And then he explains that Morgan is dumb, meaning he can&#39;t speak. And the movie is full of these old school colloquialisms too.</p>
                        <p>So now we enter, now enters Eva Moore as Horace&#39;s sister, Rebecca, who is hard of hearing. And this serves as a recurring source of humor. And it is fucking funny every single time she does it.</p>
                        <p>Dave! (45:33.576)
                        You know, she was Laurence Olivier&#39;s first wife. She was like a she was like a kind of a classic beauty of the Victorian age.</p>
                        <p>Bryan! (45:36.236)
                        No I did not. No shit.</p>
                        <p>Yeah, I saw photos of her and like portraits of her when she was young and she was quite an attractive woman. In this movie, she&#39;s a hunched old crone who seems to spend the entire movie shouting. She&#39;s like David Lynch in Twin Peaks. She insists that no one can stay, but they also have nowhere to go. And so when, oh, this is what it is. So when Horace hears about the conditions outside, he flies into a panic. Because they say something like,</p>
                        <p>You know it&#39;s flooding and there&#39;s the road is washed out and he drops the vase and it shatters into a million pieces and he is fucking terrified that they&#39;re basically stuck in this place with nowhere to go. So he seems to be like a prisoner in his own home. And it&#39;s a whole it&#39;s a.</p>
                        <p>Dave! (46:11.372)
                        The road is washed out.</p>
                        <p>This, I think this whole scene though, this movie, very short movie, this scene tells you absolutely everything you need to know about this movie. Because this is where you learn this whole movie is about secrets.</p>
                        <p>Bryan! (46:34.929)
                        Yes. Okay, so yeah, the running time of this.</p>
                        <p>Yep. So one of the things is the running time of this movie is about 70 minutes, which was kind of standard feature length for the time. If you want to watch a bunch of classic horror movies and you&#39;ve only got three hours, you can watch all of them in that time. Dracula runs that time. Frankenstein, Bride of Frankenstein, Wolfman.</p>
                        <p>Dave! (46:57.482)
                        And they&#39;re going to be 10 times better than anything you could watch that came out this year.</p>
                        <p>Bryan! (47:00.324)
                        They are lean as fuck. They get so much, so much happens in this movie in just a little over an hour. It&#39;s crazy too, because my notes are kind of anemic on this one because it&#39;s not like a snappy slasher movie with a lot of cuts and stuff. There&#39;s a lot of just long shots of wide shots of rooms with a bunch of people just bantering. So...</p>
                        <p>Dave! (47:07.79)
                        And this thing bangs along, there is no wasted time.</p>
                        <p>So this is the part where they&#39;re sort of around the fire and he makes, I don&#39;t know, he makes a toast. That&#39;s the joke, makes the toast about like, it&#39;s just something like, here&#39;s a, I&#39;m gonna make a toast that you young people might not understand, here&#39;s two illusions. Which is the fucking coolest line of the movie.</p>
                        <p>Bryan! (47:36.588)
                        He, yeah, so.</p>
                        <p>Yeah.</p>
                        <p>He does that after he serves them gin and he keeps apologizing. Yeah.</p>
                        <p>Dave! (47:51.21)
                        And he smells the gin, which apparently James Will was like, I need you to engage with the gin like before you before you serve it. I want you to engage with it. And so he&#39;s like, well, how the fuck do I engage with gin? And so he&#39;s like, all right, I guess I&#39;ll smell it because he was trying to be big. And then so he makes the smells the gin and he pours and he says, you know, two illusions. And it&#39;s that&#39;s where I think it&#39;s Melvin Douglas says something about like what does he say?</p>
                        <p>Bryan! (47:58.308)
                        No shit.</p>
                        <p>Yeah.</p>
                        <p>Dave! (48:19.734)
                        been slightly battered by the war. And it&#39;s all this way of saying, like, you know, the Pessigist&#39;s character is sort of like, he&#39;s implying that things are not what they seem here. And there&#39;s, you know, everybody has these secrets.</p>
                        <p>Bryan! (48:21.954)
                        Yeah.</p>
                        <p>Yeah, yeah, in the context of World War I also, particularly for the British, by the time that the Americans got involved, it was a meat grinder. But when the English sort of marched into World War I to sort of help France out, the way that the state sold it to them was, this was gonna be a big adventure, we&#39;re gonna go over, we&#39;re gonna...</p>
                        <p>We&#39;re gonna beat Jerry back and we&#39;re gonna have a grand old time and it&#39;s gonna be a breeze, don&#39;t worry about it. And then they went over there and they got shelled to shit and like, yeah, it was just a fucking murder festival. There is a series, it may be behind a paywall at this point from hardcore history about World War I, which is one of the most amazing fucking podcast series I&#39;ve ever heard. It really puts the whole war into perspective. It&#39;s a fucking nightmare.</p>
                        <p>Dave! (49:06.73)
                        It was an absolute blood death.</p>
                        <p>Bryan! (49:28.716)
                        But the way that the English sort of sold all of these working class kids on going over and sort of like fighting this war that, you know, they probably wouldn&#39;t have gotten, they wouldn&#39;t have gotten dragged into because it was really just about Central Europe. And they ended up just.</p>
                        <p>killed like it was something like a third of eligible men to serve like died in it like the population of the population of England. Yeah, yeah, exactly like part of the population of England was like decimated by this like it was it was completely fucking ruined and it was all kind of sold to him on a lie. Kind of the same way that Vietnam was sold to our guys where the whole Gulf of Tonkin thing was a fucking big pile of bullshit too.</p>
                        <p>Dave! (49:58.963)
                        But I mean, it&#39;s very it&#39;s very much like Vietnam.</p>
                        <p>When it comes out that, you know, what his comment is, like what everybody is saying in this scene is this is about the mask of normalcy and about what you think is normal. No one is actually normal. And this is sort of what James Whale is getting at with this movie is like, this is not everything is not what you think it is. And that&#39;s why comments like he&#39;s slightly battered by the war. That&#39;s his. And he says something else about like a crooked smile. These are really fucking sharp lines. They&#39;re fucking cool as hell. And it&#39;s all his way of saying, like, don&#39;t worry.</p>
                        <p>Bryan! (50:24.301)
                        Yeah.</p>
                        <p>No. Ah, yeah, yeah.</p>
                        <p>Bryan! (50:41.44)
                        Yeah, it&#39;s crazy.</p>
                        <p>Dave! (50:46.458)
                        I&#39;m just as fucked up because everybody got out of that war, just like Vietnam, and they were supposed to just go back and just be normal. You just watched countless people be blown to pieces in front of you. You probably inhaled tons of toxic gas, and you&#39;re supposed to just go back and get a job and shut the fuck up. This is sort of his way of being like... As much as these older people are saying, we&#39;ve lived in this place and things are not right here, but we have to pretend that they are.</p>
                        <p>So this character is saying, don&#39;t worry, I know what it is to have to pretend it. We&#39;re gonna keep coming back to that idea of having to pretend, especially once we get Charles Lawton and William Bond come.</p>
                        <p>Bryan! (51:14.381)
                        Yeah.</p>
                        <p>Yeah, I noticed a lot of like story beats in this that really reminded me of Shirley Jackson. Have you ever read We Have Out We&#39;ve always lived in the castle?</p>
                        <p>Dave! (51:30.957)
                        Yes.</p>
                        <p>Yes, because she was also someone, I mean, she was a mentally ill lesbian, basically. So everything she had to do was always coded. It&#39;s that same idea of like, we have to pretend that everything&#39;s okay, even though everything is decidedly not okay.</p>
                        <p>Bryan! (51:40.121)
                        Yep.</p>
                        <p>Goddamn, man. Yeah. Yeah, because as this movie goes on like the mask slips and slips and slips until the end happens and the whole fucking place is going crazy. But, um, Horace Also, he explains that Morgan drinks heavily and he is an uncivilized brute who gets violent when he drinks and I think all three of the Fem family members or Excluding one of them makes that remark about Morgan.</p>
                        <p>Dave! (52:17.852)
                        And we also never really find out who Morgan is, which is another, that&#39;s intentional, I think. You&#39;re never really supposed to know who he is or why he&#39;s there, but there&#39;s a lot of implications as to who he is.</p>
                        <p>Bryan! (52:22.977)
                        Yeah, it&#39;s complete.</p>
                        <p>Yeah. Oh, yeah, OK, yeah, we&#39;ll get there. But so Margaret would like to change out of her wet clothes. Yeah, this movie is packed with bangers. It&#39;s crazy. I love this scene so much. So Eva takes her to her room for privacy where she does change in one of the scenes of Sam Blematica pre-code.</p>
                        <p>Dave! (52:37.046)
                        this is another fucking cool scene. God damn it, this movie&#39;s so good.</p>
                        <p>Bryan! (52:52.544)
                        She undresses to her underwear, but it&#39;s like real old school kind of like piece of clothing. Like it&#39;s hardly revealing, but it never would have passed under the Hays Code. Would have required her to change behind like a partition or something like that.</p>
                        <p>Dave! (53:04.286)
                        And what she&#39;s putting on, I was watching this and I was like, I turned to Michael and I was like, is that a, is this such a dress she&#39;s wearing? Like, it&#39;s a, to me it looked like a slip because it&#39;s a very, it&#39;s very plain. And apparently there&#39;s a, he said, cause she was like, why am I getting into this ridiculous, like, or like a kind of ornate, very formal dress? And he told her, this is what, what&#39;s Titanic, Claire Stewart.</p>
                        <p>Bryan! (53:09.909)
                        It&#39;s basically like a ball gown.</p>
                        <p>It&#39;s very slinky.</p>
                        <p>Dave! (53:31.69)
                        She was like, why am I putting this on? Nobody else is dressed like this. Everyone else is sort of dressed in normal clothes. Why do you want me in this? And he was like, because later on, you&#39;re going to be chased through the house and I want you to look like a big, beautiful flame. And it was like, gay.</p>
                        <p>Bryan! (53:49.272)
                        Yeah. So as Margaret is changing, Rebecca is like rattling on and on and on. And she explains to her how her sister died when she was a young woman. Rebecca is very severe and intense. And the way she dresses is like very like old Baba. And she has this like apron with all these instruments and shit hanging off of like scissors. Very puritanical. Because one of the things that she says is,</p>
                        <p>Dave! (54:11.466)
                        and she&#39;s super puritanical.</p>
                        <p>Bryan! (54:17.996)
                        She explains to her that her sister was supposedly hot, and like all the dudes were like into her.</p>
                        <p>Dave! (54:22.434)
                        Yeah, she says she has a long neck and red lips and big eyes. I&#39;m like, okay, well, that doesn&#39;t sound hot to me, but I get it, it&#39;s 1932.</p>
                        <p>Bryan! (54:26.508)
                        Yeah. But, uh, whale. But, uh, whale does something really wild here. So as she is talking, she&#39;s getting like she&#39;s really building in intensity, she&#39;s getting more and more like, irate and weird. And he shoots Rebecca at first, like as she&#39;s in a like a two shot with Margaret, but then</p>
                        <p>Dave! (54:33.038)
                        You didn&#39;t have the internet yet.</p>
                        <p>Bryan! (54:55.604)
                        It switches to, he&#39;s shooting Rebecca in the reflections of all these warped mirrors in the room. And the more she talks, he switches to another one. And then he switches to another one. And like each time he does it, like her reflection gets a little more fucked up.</p>
                        <p>Dave! (55:00.645)
                        Yeah, this shit&#39;s wild.</p>
                        <p>And her voice is also getting a little distorted.</p>
                        <p>Bryan! (55:13.376)
                        I did not notice that, but.</p>
                        <p>Dave! (55:14.806)
                        So there&#39;s an interesting thing about this, because what she&#39;s going on about is like, essentially what she&#39;s telling her is, our sister was slutty, is kind of what she&#39;s getting at.</p>
                        <p>Bryan! (55:22.924)
                        Yeah. She says, well, what she really does is like, everybody in this house was godless and they were heathens and they would have these parties and everybody would have a good time. And then they paid for it.</p>
                        <p>Dave! (55:31.118)
                        Because the house was like the center of town. It&#39;s like where everyone would come for social gatherings. It was a fun place until something happened.</p>
                        <p>Bryan! (55:36.686)
                        Yeah.</p>
                        <p>Right, and later on Sir Roderick also kind of goes back over this, but almost in a kind of a Rashomon situation where his recollection of this period is way different than Rebecca&#39;s.</p>
                        <p>Dave! (55:52.598)
                        Well, because Rebecca, so the thing I was noticing about this rant that Rebecca is going on, because she&#39;s, you know, it&#39;s all very, everything&#39;s coded that she&#39;s, I mean, everything in this movie is coded. But she&#39;s going on and on, it&#39;s a lot of like religious hyperbole and kind of a lot of fanaticism, and she&#39;s getting really worked up. And, you know, she is also like, this character, I don&#39;t know if she&#39;s supposed to represent moral outrage and like religious conservatism, but.</p>
                        <p>that she&#39;s sort of there with her, she&#39;s using her righteousness to condemn things that it is implied that she feels robbed of. Like her sister was this thing, and she&#39;s not upset that her sister was kind of promiscuous or whatever, like it doesn&#39;t sound like she&#39;s promiscuous, but I guess by 1931 standards she was. But it&#39;s more like they were all doing these things and they wouldn&#39;t let me do it too. And that her anger comes from this place of like,</p>
                        <p>Bryan! (56:47.565)
                        Yeah.</p>
                        <p>Dave! (56:48.778)
                        you left me out, it resonates so much, especially right now, where it&#39;s like, you&#39;re all having fun, you&#39;re not including us, or you&#39;re having fun at our expense. And it&#39;s like, so, and so we can&#39;t have that. So we&#39;re going to then go way the other direction, judge the shit out of you and try to destroy.</p>
                        <p>Bryan! (56:57.972)
                        Yeah!</p>
                        <p>Yeah, yeah, I mean, it seems like the kind of current wave of sort of like official state homophobia is a I hesitate to say a kind of a reasonably recent thing but maybe like the last 40 years, it kind of seems to coincide with the rise of like Real the religious right.</p>
                        <p>in like the Reagan years. I mean, it was certainly existed before that, but it seemed to reach a kind of focused intensity with.</p>
                        <p>Dave! (57:41.034)
                        I will take us way off the rails if I get into explaining all of that, but essentially a lot of it is, it is a reaction to feminism, basically. I mean, a lot of homophobia is rooted in misogyny, essentially. It&#39;s a hatred of women more than anything else. That&#39;s why most of homophobia is directed at men. And so, and I don&#39;t mean to discount, you know, things that aren&#39;t directed at men, but.</p>
                        <p>Bryan! (57:44.364)
                        Yeah.</p>
                        <p>Yeah.</p>
                        <p>Yeah, yeah.</p>
                        <p>Bryan! (57:59.468)
                        Yeah.</p>
                        <p>Bryan! (58:04.057)
                        No.</p>
                        <p>Dave! (58:06.674)
                        There&#39;s a lot of that. And that&#39;s I feel like in this, because the thing she does next is she&#39;s ranting about beauty and she&#39;s ranting about her sister&#39;s beauty. And then she reaches out and touches her in this very sexual way. She touches the dress and then she touches her chest. And we&#39;re gonna come back to that later in the coolest fucking scene ever shot in any movie ever.</p>
                        <p>Bryan! (58:06.745)
                        Yep.</p>
                        <p>Yeah, she touches the dress. Yeah. Yes. Yeah.</p>
                        <p>Bryan! (58:27.408)
                        Oh my God, yes. Yeah, so honestly, I think in the context of the book, this scene is very much an attack on the church. The Anglican church is really kind of like the model of state-approved religion. And like, yeah.</p>
                        <p>Dave! (58:41.642)
                        And this was a thing that he did a lot. He would use his movies a lot to take digs at Catholicism essentially for obvious reasons.</p>
                        <p>Bryan! (58:49.332)
                        Oh yeah, for sure. But also one of the sort of key takeaways in the scene is that Rebecca&#39;s father is still alive in the house and he&#39;s very old, he&#39;s 102 years old.</p>
                        <p>Dave! (59:03.65)
                        And what I will say, this was my other thought about these two is like, so what we had, again, going back to that idea of secrets is the only two people in this house who cannot keep that or will not keep that secret are the two people who are locked away.</p>
                        <p>Bryan! (59:17.776)
                        Yeah. God damn, man. 70 fucking minutes. Yeah. So following this, Margaret considers her own reflection in the warped mirror. And as she does this, we see like flashes of the speech again, like whatever it was.</p>
                        <p>Dave! (59:23.722)
                        Yeah, no, they waste nearly a second.</p>
                        <p>Bryan! (59:37.388)
                        like Rebecca really fucking gets to her and again we&#39;ll revisit it later in oh god i cannot wait to fucking talk about that scene so now</p>
                        <p>Dave! (59:46.878)
                        And I think there&#39;s a weird queer coding to Rebecca as well in that moment, because she is sort of getting closer and touching her in this weird, like in this aggressive way that&#39;s very much like you didn&#39;t let me have this. People like you wouldn&#39;t let me have this.</p>
                        <p>Bryan! (59:50.999)
                        Oh sure!</p>
                        <p>Cuz</p>
                        <p>Bryan! (59:58.324)
                        Right. Cause what she does, what she does is she points at her with her finger first. She points at the dress, she touches the dress and she says like, this too will rot. And then she goes and she looks like she&#39;s going to touch her with her finger on her chest, but then she puts her whole fucking palm of her hand on her chest. And then she, this too will rot. And that like freaks Margaret out understandably. Jesus Christ, man. It is such an intense scene.</p>
                        <p>Dave! (01:00:15.178)
                        Yeah. And then she says, this too will rot.</p>
                        <p>Freaks me out too, it&#39;s fucking cool as hell.</p>
                        <p>Bryan! (01:00:27.964)
                        But now we cut to the dinner scene. And in another pre-code scene, Rebecca suddenly objects to Horace cutting the meat without blessing the meal first, and Horace leaps into a mockery of religion. I can&#39;t remember exactly.</p>
                        <p>Dave! (01:00:43.678)
                        And do you know what he says?</p>
                        <p>Something about tribal rituals, like it is very dismissive.</p>
                        <p>Bryan! (01:00:49.196)
                        He calls it her tribal ritual. Yeah. And he&#39;s like, he&#39;s not openly like being blasphemous or anything. He&#39;s just explaining to the guests what, like how he sort of interprets her obsession with religion. And at the other end of the table, she&#39;s like, I can, I see you being blasphemous. I see, like, cause she can&#39;t hear him.</p>
                        <p>Dave! (01:01:10.71)
                        Yeah, Horace is basically a big gay eye roll of a character. Everything he does is sort of like, well, she was gonna plant these flowers, but fuck it. Like everything is just like this bitch. Oh my God.</p>
                        <p>Bryan! (01:01:24.925)
                        Yeah. So they get into it and he&#39;s got this huge roast beef in front of him, but he keeps insisting that everybody have a potato. And there are long lingering shots of everybody eating at the table. And it looks like if it were in color, I guarantee it would look like food on Star Trek.</p>
                        <p>Dave! (01:01:35.342)
                        This fucking food, this food is the most, this is the most British looking food I&#39;ve ever seen.</p>
                        <p>And there&#39;s something about the brown specks in the potatoes that I don&#39;t get. But even like Gloria in that in the commentary, Gloria Stewart is like, we were supposed to be looking for brown specks or black specks, but I don&#39;t really remember seeing any of them. But then they cut. I don&#39;t know if it&#39;s Melvin Douglas or the other Canadian guy whose name I can&#39;t remember. But one of them does find like these big chunks.</p>
                        <p>Bryan! (01:01:46.52)
                        That&#39;s the thing, okay, so I don&#39;t get it either. So they&#39;re.</p>
                        <p>Yeah. Right. So, so, um, uh, everybody&#39;s eating at the table. Margaret eats only a potato, and it is a unblemished potato, very, very boiled. Philip...</p>
                        <p>Dave! (01:02:20.502)
                        Yeah, this is the whole thing. The whole thing looks like it was all cooked in one pot. On the Welsh countryside.</p>
                        <p>Bryan! (01:02:23.732)
                        Yeah, so Philip continually picks something dark out of the potato, and it&#39;s never addressed, it&#39;s never explained, and we even go over it twice, and we cut over to Rebecca, who is shoveling her meal into her, just shoveling it down her throat.</p>
                        <p>Dave! (01:02:40.554)
                        Yeah. And apparently they had to do this scene like it took them like five hours to the scene. So she is basically shoveling food into her face for five hours. And we&#39;re like, we&#39;re not fucking joking. Like she is cramming food in her face, like with her hands, bread, pieces of bread, everything. She&#39;s just fucking cramming it in there.</p>
                        <p>Bryan! (01:02:46.159)
                        Oh my god.</p>
                        <p>Yeah.</p>
                        <p>Bryan! (01:03:00.28)
                        Also, yeah, they pretty they pass a slice of bread down the table you look impaled on a knife Yeah, so as there is I Thought we were gonna get through without a Chekov&#39;s axe I Know yeah, but um, suddenly there&#39;s a knock at the door And Morgan admits two more people who were stuck out of the storm. Oh my god These two are nuts</p>
                        <p>Dave! (01:03:06.57)
                        Yeah. That&#39;s Chekhov&#39;s fucking bread knife is what that is.</p>
                        <p>Yeah, I had to come up with something. It was the best I could do.</p>
                        <p>Dave! (01:03:22.902)
                        Ladies and gentlemen, it&#39;s the good time, gals.</p>
                        <p>Bryan! (01:03:28.132)
                        They&#39;re like the opposite of the three who came in previously. It&#39;s so great. It&#39;s a big.</p>
                        <p>Dave! (01:03:32.278)
                        And that is intentional. So apparently, so it is Charles Lawton and Lilian Bond come bursting into the door, and they&#39;re very like lively. But apparently Charles Lawton had spent like a half an hour or something running back and forth and back and forth up and down the soundstage. And Gloria Stewart was like, what the fuck is he doing? And James Well was like, he&#39;s getting ready for his big entrance where he&#39;s supposed to look like he&#39;s big and out of breath. And she&#39;s like.</p>
                        <p>Bryan! (01:03:38.06)
                        Yeah. So it.</p>
                        <p>Dave! (01:03:59.318)
                        Well, I can do that too, and I don&#39;t have to run up and down. And apparently James, I was like, can you?</p>
                        <p>Bryan! (01:04:02.457)
                        Ha ha.</p>
                        <p>Yep, so Charles Lawton is playing a big fat guy named Sir William Porterhouse. Big loud... Yep, big loud fat...</p>
                        <p>Dave! (01:04:12.986)
                        Yeah, this is his first American movie. And apparently, you know, if nobody if people don&#39;t understand, Charles Lawton is one of the greatest actors of all time. This is a man he is. He&#39;s one of those fucking people that I hate a lot because it&#39;s like this is a guy who made he directed one film and it is the one of the coolest fucking movies ever made. Everybody looked at it and they were like, God damn, what did you just do?</p>
                        <p>Bryan! (01:04:23.808)
                        Yeah.</p>
                        <p>Dave! (01:04:40.662)
                        You just broke tons of new ground. He was like, yeah, but you know what? I don&#39;t really like directing. I think I&#39;m good. Fuck you, man.</p>
                        <p>Bryan! (01:04:49.467)
                        You gonna name that movie?</p>
                        <p>Dave! (01:04:51.69)
                        Oh, it&#39;s Night of the Hunter.</p>
                        <p>Bryan! (01:04:53.12)
                        Yeah, God, that movie is so fucking good, dude.</p>
                        <p>Dave! (01:04:55.286)
                        which is one of the coolest fucking movies ever made. But yeah, he was just like, nah, I think I&#39;m okay. I know I&#39;m really good at this, but I&#39;m just gonna let that die on the vine if it&#39;s cool. He was also married to Elsa Lanchester.</p>
                        <p>Bryan! (01:04:58.28)
                        It really is. That&#39;s so crazy.</p>
                        <p>Yeah, my God. No, shit, that&#39;s great. Herself, I suppose there&#39;s Pride next year. We&#39;ll talk about Elsa Lanchester.</p>
                        <p>Dave! (01:05:15.634)
                        Yeah, I mean, we are. So he is the James Whale and Thessager. These are the two obvious gay men in the film. Charles Lawton is the third.</p>
                        <p>Bryan! (01:05:25.708)
                        Yeah. So he&#39;s a big, loud fat guy. And he comes in with his friend who is a little lady played by Lillian Bond named Gladys Duquesne, who is just a friend of his.</p>
                        <p>Dave! (01:05:37.666)
                        These and this fucking casting is so brilliant because it&#39;s like if you you&#39;ve got the three who come in and they&#39;re very prim and very proper and then you&#39;ve got these two and you could not. This is where the class thing comes into play and they&#39;re so obviously different. Like they are, you know, Charles Lawton is he&#39;s disheveled and not just because of the rain, but like he bursts in he&#39;s whipping his coat in front of the fire. And then Lily Bond comes in and she is like she is a show girl. Like she&#39;s they&#39;re loud, they&#39;re brash, they&#39;re they do not.</p>
                        <p>Bryan! (01:05:49.326)
                        Yeah.</p>
                        <p>Yeah.</p>
                        <p>Dave! (01:06:07.406)
                        ask to be let in, they just come in.</p>
                        <p>Bryan! (01:06:10.828)
                        Yeah, yeah, that&#39;s right. Like there&#39;s it was almost like it was a foregone conclusion that they were welcome. It&#39;s it&#39;s.</p>
                        <p>Dave! (01:06:15.306)
                        Yeah, there&#39;s no formality to them. They&#39;re just like, of course you&#39;re going to let us in. We are who we are. And you know what? If I&#39;m being honest, I&#39;d be OK with that. I&#39;d be like, of course I&#39;m going to let you in. You&#39;re Charles Laughton and Lillian Bond. I&#39;m me on a Sunday night.</p>
                        <p>Bryan! (01:06:23.221)
                        Iowa too.</p>
                        <p>I think in any other movie that these two would be obnoxious bad guys, but they&#39;re not. They&#39;re very nice, they&#39;re very likeable, they have great chemistry. Actually, the whole cast has great chemistry.</p>
                        <p>Dave! (01:06:35.986)
                        Oh, they&#39;re charming as fuck!</p>
                        <p>And weirdly enough, they never talked to each other. Apparently the British contingent, so I knew this before I listened to the commentary, but Gloria Stewart talks about it as well. And she seems still kind of, well, not still, she&#39;s dead now, but at the time, she seems kind of bitter about it still. The British contingent on this film would go and sort of be amongst themselves. They would have tea, they would have, as she calls it, 11sies and 4sies.</p>
                        <p>Bryan! (01:06:48.217)
                        That&#39;s wild.</p>
                        <p>Dave! (01:07:12.842)
                        Which always reminds me of what&#39;s that fucking movie with the ring and the hobbits? Reminds me of that one. There you go, that one. So it reminds me of that. But like, apparently, they would just be like, oh, we&#39;re not inviting the Americans. And, you know, again, and I don&#39;t want to get patriotic, but it&#39;s like, look, motherfucker, you wouldn&#39;t be here if it weren&#39;t for the Americans. And, you know, they&#39;d probably be OK with that. But yeah, apparently, they were like, really like, oh, we&#39;re not here for you.</p>
                        <p>Bryan! (01:07:19.178)
                        the Lord of the Rings. Yeah, that one.</p>
                        <p>God damn it.</p>
                        <p>Bryan! (01:07:36.838)
                        Yeah, that was...</p>
                        <p>Dave! (01:07:41.698)
                        Like there was no small talk. There was no like hanging out. Like that was sort of something James Whale did intentionally. It was like, there&#39;s no downtime. You&#39;re not going to get to know each other. And you can, I don&#39;t know. It&#39;s weird. Like you don&#39;t feel that. I mean, you feel it a little bit later on, but.</p>
                        <p>Bryan! (01:07:50.606)
                        Yeah.</p>
                        <p>No, it does not come that. Sure, but like they seem to get they&#39;ll get along really great. But OK, so I did. I never mentioned anything in like as we were texting and all that. I don&#39;t know if you noticed this. This is the first of several shots in the movie which put great emphasis on Lillian Bond&#39;s feet. For it.</p>
                        <p>Dave! (01:08:18.466)
                        There is, and I don&#39;t think it&#39;s for like Tarantino-esque reasons.</p>
                        <p>Bryan! (01:08:23.368)
                        I was gonna say, because like, you would think Tarantino is directing this movie, because it&#39;s like...</p>
                        <p>Dave! (01:08:28.622)
                        I think it&#39;s to show, I think the representation, what that&#39;s demonstrating or representing is like, you have Gloria Stewart in this dress, this big, beautiful dress, and she is wet, so she goes and changes into this big dress, it&#39;s gorgeous. And then you have Lillian Bond who shows up, she&#39;s also just as soaked and muddy and whatever, and so the best you can ever do for her character, and this becomes important later, is to just sort of change her shoes.</p>
                        <p>Bryan! (01:08:39.148)
                        Yeah. Okay.</p>
                        <p>Right, because what her shoes are all soaked and she takes them off and Penderel comes over and gives her a dry pair of his shoes, which are massive on her. But like a great deal of emphasis is played on this.</p>
                        <p>Dave! (01:09:10.154)
                        And it&#39;s supposed like this is the one part of the movie that sort of doesn&#39;t work is the chemistry between Melvin Douglas and Lillian Bond. It&#39;s not quite there.</p>
                        <p>Bryan! (01:09:21.26)
                        It&#39;s a little forced. But yeah, because this is not the first time it happens, it&#39;s a recurring theme that shows up. So I&#39;ve noted every time it happens. But basically, Gladys and Pandrell immediately hit it off. And then everybody goes back over to the table, where once again Horace offers everybody a potato. And then.</p>
                        <p>Dave! (01:09:43.undefined)
                        But he doesn&#39;t just offer, he&#39;s like, here, have a potato. As if to say, shut up.</p>
                        <p>Bryan! (01:09:46.368)
                        Yeah, yeah. Yep. What is this? I should like a potato.</p>
                        <p>Dave! (01:09:51.533)
                        There&#39;s something that they&#39;re all kind of hanging out or something. And Charles Lawton says something like, well, there&#39;s no accounting for taste. And Horace sort of sniffs at him derisively and goes, no.</p>
                        <p>Bryan! (01:10:05.549)
                        So we cut to a conversation by the fire side where everybody is like just gathered around this huge this huge fireplace. And the topic of conversation turns to the old feminine intuition and the women take to profiling all the guys. And what is it Gladys notes a big difference between Penderel and Porterhouse.</p>
                        <p>The former is a big is a romantic who doesn&#39;t really jive with the new capitalist landscape of post-war England while Porterhouse does. One of the things that she says, and this goes back to one of the things where you were talking about double entendres, is she says very loudly, oh he likes to pretend that he&#39;s gay. Meaning, oh really? Because yeah, because I mean really, you know, obviously what she means is, oh that he likes to play like he&#39;s a big happy guy.</p>
                        <p>Dave! (01:10:45.055)
                        So that comes that&#39;s later. She says that later. Yeah.</p>
                        <p>Because this is where we get into this idea. So Porterhouse is that&#39;s Lawton&#39;s character, right? So he&#39;s sort of nouveau riche about. Yeah, he&#39;s sort of nouveau riche, like he marries up and the kind of long and the short of it all is sort of like he&#39;s trying to jump his station in life, like he&#39;s trying to trade up by marrying this wealthy woman. And it&#39;s they.</p>
                        <p>Bryan! (01:10:57.58)
                        Porter, yeah, so this is, yeah, it&#39;s Porterhouse. This is where he tells that story, right.</p>
                        <p>Dave! (01:11:20.598)
                        They say like he is she ends up dying or whatever. Like they really breeze past this part.</p>
                        <p>Bryan! (01:11:26.244)
                        they do and there&#39;s an implication in there because what the story is this that they, he marries this woman who he who&#39;s his true love and they&#39;re invited to this big society party and he buys his wife a cotton frock and they go to the party and they&#39;re kind of mocked for it. Like it&#39;s not, you know, it&#39;s like a cheap frock or something like that.</p>
                        <p>Dave! (01:11:49.846)
                        Yeah, like they&#39;re always he&#39;s all they&#39;re both always on guard about being perceived as poor or not good enough, even though they have money. But they didn&#39;t earn it. They didn&#39;t come by it like through family. They&#39;re they&#39;re they sort of came by it, I think, for her father or something like that. So it just it doesn&#39;t work out the same way that it should, essentially.</p>
                        <p>Bryan! (01:11:58.233)
                        Yeah.</p>
                        <p>Yeah. And so, yeah, he says, I believe that it&#39;s that reason, it&#39;s that thing that killed her. And it&#39;s like, what the fuck? How did she die? Like, did she fucking kill herself or something?</p>
                        <p>Dave! (01:12:18.546)
                        Yeah, and this I and we will get to I have I have a theory about this and I will get to later.</p>
                        <p>Bryan! (01:12:25.26)
                        Yeah. But we also learned in this scene that Gladys Duquesne is not her real name. It&#39;s Gladys Perkins, and she is a showgirl.</p>
                        <p>Dave! (01:12:32.714)
                        And here we come back again to that idea of illusions and of secrets. She is not what she says she is. She is someone totally different.</p>
                        <p>Bryan! (01:12:37.186)
                        Yeah.</p>
                        <p>Yep, yep. So Rebecca charges in and she announces that Morgan has been drinking and he is quite drunk. Her tone implies that this is a capital B problem.</p>
                        <p>Dave! (01:12:53.799)
                        It&#39;s gonna be.</p>
                        <p>Bryan! (01:12:54.844)
                        Yes it is. So Gladys and Pandril, they&#39;re paired up immediately. They&#39;ve got some...</p>
                        <p>Dave! (01:13:03.566)
                        Because he says, I have a bottle of whiskey out in the car. I&#39;ll go get it. And she, and this is another sharp contrast to the Gloria Stewart character. Again, I can&#39;t remember their actual names. I&#39;m playing by what&#39;s called mom rules, where you just, they can&#39;t remember, you&#39;re just calling by the actor name. So she&#39;s just like, well, no, I&#39;m gonna come with you. I wanna have an adventure. I&#39;m coming with you. And he&#39;s like, no, that&#39;s improper. And she&#39;s basically like.</p>
                        <p>Bryan! (01:13:16.244)
                        Margaret something.</p>
                        <p>Yeah.</p>
                        <p>Dave! (01:13:31.15)
                        Fuck off, I&#39;m coming with you. I don&#39;t give a shit if you think it&#39;s improper. Which is kind of like, or again, you&#39;d think these people would be obnoxious, and I don&#39;t know if they&#39;re supposed to be, but they&#39;re charming as hell. We&#39;re like, all right, I&#39;d go to the car with Lillian Bond too.</p>
                        <p>Bryan! (01:13:32.738)
                        Yeah.</p>
                        <p>Yeah, yeah.</p>
                        <p>Bryan! (01:13:47.24)
                        Oh yeah, she&#39;s very, very charming. She&#39;s very pretty. So yep. Yep.</p>
                        <p>Dave! (01:13:51.458)
                        She&#39;s the fucking showgirl. What are you gonna do, hang out with Rebecca or whatever her name is? The weird, sad lesbian? No!</p>
                        <p>Bryan! (01:13:59.078)
                        So yeah, and just like side note showgirls you probably know, but they&#39;re like the chorus line at a musical review, you know, they&#39;re the big group of dancers think like Busby Berkeley type things, you know, she&#39;s kind of like an all purpose dancing girl.</p>
                        <p>Dave! (01:14:16.886)
                        And that&#39;s like big time. The sort of second tier to that is like club performers. Like they would be part of the floor show.</p>
                        <p>Bryan! (01:14:22.552)
                        Yeah, yeah. Yep. So, yeah, they go out, Zora closes behind her, and she goes running after Panderel, who&#39;s gone to the barn to get this thing. And as she&#39;s out there, she looks into the kitchen window. She sees Morgan stumbling around, like doing the biggest Torggo impression I&#39;ve ever seen. Yeah, and he just casually punches out a window. Like it&#39;s nothing.</p>
                        <p>Dave! (01:14:43.001)
                        Yeah, and again.</p>
                        <p>Like, it&#39;s no fucking thing. And she just breezes past it. She&#39;s like, not my chair, not my problem. Like, it&#39;s just. Just keeps going. She&#39;s like, I got Melvin Douglas and whiskey in the car, and you know what? Going to be honest, I&#39;d go there, too. Yeah. If I&#39;ve got Torgo breaking windows or Melvin Douglas and getting drunk. It&#39;s a no brainer.</p>
                        <p>Bryan! (01:14:52.76)
                        He&#39;s like, nope, not dealing with that. Yep. No way.</p>
                        <p>Yeah.</p>
                        <p>Bryan! (01:15:06.572)
                        He&#39;s a handsome fella.</p>
                        <p>Bryan! (01:15:13.74)
                        Yep. So she joins them out at the barn. They spend some time drinking. We get more of Lillian Bond&#39;s feet.</p>
                        <p>Dave! (01:15:21.878)
                        This scene though is remarkable for so many reasons. This is the moment where I had to look up again. I was just like, this, cause I couldn&#39;t remember when the Haze Code was. And so, and I was fairly certain this was before that, but I had to look it up because this whole conversation defies that in so many ways.</p>
                        <p>Bryan! (01:15:42.06)
                        Yeah, yeah, so I&#39;ve got notes about that. So that comes a little bit. There&#39;s a little bit more sort of in between. We go back to the house, and this is when the lights go out.</p>
                        <p>Dave! (01:15:52.717)
                        This has one of the greatest moments in the entire movie.</p>
                        <p>Bryan! (01:15:55.404)
                        Yeah, so a good deal of to do is made about getting the lamp from the top of the landing. This is an oil lamp up there</p>
                        <p>Dave! (01:16:02.55)
                        So all the lights go out and the Horace basically, I can&#39;t remember exactly what he says, but he&#39;s basically just like, well, I guess we&#39;ll all be miserable together now.</p>
                        <p>Bryan! (01:16:11.284)
                        Yeah, yeah. So he is terrified by the prospect of having to go up and get this lamp. And Rebecca menaces him. She&#39;s really, really good at like fucking bullying people.</p>
                        <p>Dave! (01:16:23.85)
                        Yeah, they are. Have you ever seen burnt offerings? So is that I think it&#39;s Eva Gardner and that as well, maybe it&#39;s so Jesus Christ, I can&#39;t remember anyone&#39;s name right now. Burgess Meredith, and I think it&#39;s Eva Gardner, their brother and sister, and they also have that energy of like, they&#39;re just sniping at each other the whole time.</p>
                        <p>Bryan! (01:16:27.982)
                        Yeah.</p>
                        <p>Mm-hmm.</p>
                        <p>Bryan! (01:16:45.824)
                        Yeah. Oh yeah, they&#39;re two people who&#39;ve been trapped in a house together for, you know, ever.</p>
                        <p>Dave! (01:16:49.17)
                        And she uses every opportunity in this movie to bully him. Like they he goes out of his way so that you understand she controls everything in this house, including Horace.</p>
                        <p>Bryan! (01:16:59.908)
                        Yeah, I would guarantee that this movie had an impact on that one. Cause also a little later on, one of the things that Roderick says is, he wants this house to be a burnt offering. Yeah, so Philip and Horace go up to get the lamp, but Horace keeps making excuses about going up to get him. At one point, he&#39;s like, I...</p>
                        <p>Dave! (01:17:27.247)
                        I have something I want to show you in my room. Fuck yeah you do!</p>
                        <p>Bryan! (01:17:29.78)
                        in my room. Like, I&#39;d really like, it&#39;s like I&#39;d really like to, there&#39;s two things in this house that I&#39;d really like you to see and one of them is my room and I&#39;m like, come on.</p>
                        <p>Dave! (01:17:40.254)
                        And like, it&#39;s clear he&#39;s trying to get out of going up, but it&#39;s also like, it&#39;s also clear what he&#39;s trying to do too.</p>
                        <p>Bryan! (01:17:46.296)
                        Yeah, yeah, because they I mean I can&#39;t this is this thing is I the guy playing Philip is I can&#39;t remember his name Also, he&#39;s a handsome fella No shit, thanks Canada Yeah, but uh, and so Philip is like that. Yeah, sure. I&#39;ll like I&#39;ll check it out But like after we get this lamp like we&#39;re on a mission here guy, and that&#39;s what that&#39;s what that&#39;s what</p>
                        <p>Dave! (01:17:53.752)
                        He&#39;s Canadian, no less.</p>
                        <p>I dunno.</p>
                        <p>Dave! (01:18:06.447)
                        He said I love what he&#39;s just like, I&#39;m too fragile to go. And I was just like, what is this fucking lamp made of?</p>
                        <p>Bryan! (01:18:13.152)
                        I know I know that this is when Horace kind of levels with him he&#39;s like look I&#39;m not going up there you can do it and he&#39;s like look the light because he keeps saying like they have the lips very heavy and you know the vanity of age and I can&#39;t you know I can&#39;t lift what I used to and then it. Yep and eventually he&#39;s like oh yeah i&#39;ll go up and get it myself fine whatever and he&#39;s like oh yeah sure.</p>
                        <p>Dave! (01:18:25.95)
                        He&#39;s probably just like, nope, sorry, dude, not going with you.</p>
                        <p>They have that great moment too where she&#39;s just where Rebecca is. Or is Rebecca? That&#39;s her name, right? When she&#39;s just like, you know, you know where that is, right? The landing. It&#39;s on the stairs there between the. She&#39;s just like, get up there, get up there, queer.</p>
                        <p>Bryan! (01:18:37.508)
                        That&#39;s her name. That&#39;s his sister.</p>
                        <p>Up the landing, at the end of the landing.</p>
                        <p>Bryan! (01:18:48.236)
                        Yeah.</p>
                        <p>Bryan! (01:18:52.16)
                        Yep. So Philip goes on alone and he does find the lamp next to a door that is bolted and</p>
                        <p>Dave! (01:18:56.854)
                        Yeah, and it is basically a Tiffany hurricane lamp. I was, I don&#39;t know what I was expecting, because I hadn&#39;t seen it in a while, but I was just like, I don&#39;t remember the lamp, but like the way they talk about it, where he&#39;s just like, oh, I can&#39;t lift it, and he gets it there. This is the fanciest fucking hurricane lamp I&#39;ve ever seen.</p>
                        <p>Bryan! (01:19:01.181)
                        Yeah.</p>
                        <p>It&#39;s crazy. Yeah, it&#39;s very, it&#39;s very fancy. Now, if I&#39;m being honest, when they kept talking about this, like the threatening lamp, I kept thinking of Amityville 4.</p>
                        <p>Dave! (01:19:22.347)
                        Oh, you mean like with a demon.</p>
                        <p>Bryan! (01:19:24.184)
                        The demon lamp, not a demon lamp. It&#39;s just, it&#39;s a very ornate like oil lamp, double oil lamp, fancy as hell. Philip goes on alone. Yeah, but also, but like right next to the, right next to this bolted double locked door is a table, which also has like a plate of food. So it&#39;s very heavily implied that somebody is in there obviously. But also this is the first time we hear that, hee hee, like laughter from like upstairs.</p>
                        <p>Dave! (01:19:30.934)
                        Fancy as hell. But probably not that heavy.</p>
                        <p>Yeah, this is some straight-up leprechaun bullshit.</p>
                        <p>Bryan! (01:19:56.866)
                        So back on the ground floor, a great deal of noise is being made about closing the window.</p>
                        <p>Dave! (01:20:06.582)
                        But because someone has opened the window and she says, she&#39;s sort of like Rebecca, sort of in the dining or wherever they are. She said, well, who someone has opened the window, who is up in the window and glaring towards like, yeah, who opened the window? But a terrible thing to do. I we should do something about it.</p>
                        <p>Bryan! (01:20:21.649)
                        Yeah. No but- Penderil is like fine I&#39;ll you know I&#39;ll go close it don&#39;t worry about it. And so while alone Margaret takes some time to make shadow puppets on the wall and this is a very it&#39;s a very silly scene because at first she&#39;s like you know horns you know moose horns and.</p>
                        <p>Dave! (01:20:39.122)
                        See you never.</p>
                        <p>Bryan! (01:20:46.204)
                        Um, just like hopping like a bunny and two dogs talking to each other</p>
                        <p>Dave! (01:20:49.59)
                        But it all starts, she starts very close up. So it&#39;s just, it would be like if you were standing in front of a thing and you&#39;re making little dog puppets or whatever, like shadow puppets, and she backs up.</p>
                        <p>Bryan! (01:20:52.56)
                        and sh-</p>
                        <p>And then she steps back. And Rebecca&#39;s shadow is suddenly very big. No, no, no. Margaret&#39;s shadow is suddenly very big. But then, no, I didn&#39;t. Rebecca&#39;s shadow suddenly emerges from her own.</p>
                        <p>Dave! (01:21:06.314)
                        You blew it. You blew it.</p>
                        <p>And the only thing that sucks about this is you can sort of see her come into the frame.</p>
                        <p>Bryan! (01:21:17.104)
                        I did, I only on the second viewing did I notice that cause I was looking for it. But the first time it happened, I was like, holy shit, that was awesome.</p>
                        <p>Dave! (01:21:25.89)
                        Yeah, because she comes out of her shadow.</p>
                        <p>Bryan! (01:21:28.112)
                        she comes out of her shadow and what she does is the shadow turns to her and does the gesture like that she did when she was like in Disbel Rot 2 and Margaret.</p>
                        <p>Dave! (01:21:38.086)
                        And then she also puts her whole hand on her.</p>
                        <p>Bryan! (01:21:41.056)
                        Yes, and so but and so the first time I watched this I I&#39;d forgotten that Rebecca leaves with Uh with porterhouse And so I thought that she was in their room with her It wasn&#39;t until the second viewing that I was like, oh shit like this that speech she had is haunting her Um</p>
                        <p>Dave! (01:22:00.234)
                        Yeah. Like you&#39;re not. And it&#39;s not like it&#39;s I think if you watched it today, most people will be like, wait, is this a supernatural thing? And it&#39;s not. It&#39;s something she&#39;s still sort of lingering on.</p>
                        <p>Bryan! (01:22:09.55)
                        No.</p>
                        <p>Yeah, it&#39;s all in her head, because like this, that, it&#39;s like a leitmotif. It keeps coming back, like whatever that was, that sort of fear of like, I might turn into this woman or I might be punished for this, you know, this.</p>
                        <p>Dave! (01:22:25.026)
                        But that&#39;s that mask of normalcy again, where she&#39;s just sort of like, no, we&#39;re prim and proper, but out of her shadow play comes this other thing. There&#39;s always this lingering problems and lingering things that make you less than sort of status quo.</p>
                        <p>Bryan! (01:22:36.791)
                        It is.</p>
                        <p>Yeah, it is a fucking amazing scene. Like the, the God damn, like I don&#39;t really know how to really kind of articulate. You just, you gotta see it. If you hadn&#39;t already watched it, go and watch it. Like this, this movie like gets my, like my full vocal support. Like I&#39;m really into it. This and that, that scene is like a, like a flag right in the middle of the whole thing. Like it is so fucking perfect. And it was, it must&#39;ve been.</p>
                        <p>planned and rehearsed a thousand times to get it right. Because the way that it works is just, it&#39;s so cool.</p>
                        <p>Dave! (01:23:14.744)
                        And they I mean, they build up to this. So like, I think in most movies, this would feel unnatural. It would feel very kind of shoehorned in with the use of shadow in this movie from the very beginning makes it feel less awkward. She&#39;s like, oh, because the whole movie, you&#39;re just watching people&#39;s shadows on the wall.</p>
                        <p>Bryan! (01:23:30.552)
                        Mm-hmm.</p>
                        <p>Yeah, oh, that&#39;s the thing is this movie the movies like production design like the art design of the movie is very deliberate. Yep, it is a The design of the house mostly takes place on the first floor and it&#39;s it looks like a German expressionist set like sharp angles and it&#39;s very</p>
                        <p>Dave! (01:23:34.894)
                        They&#39;re always big.</p>
                        <p>Now this is a lockbox drama.</p>
                        <p>Dave! (01:23:57.262)
                        I swear to God it&#39;s the same set they use for Son of Frank.</p>
                        <p>Bryan! (01:24:00.756)
                        They might have. Like, I&#39;d imagine that they just kept this shit in storage on the back line, just busted it out.</p>
                        <p>Dave! (01:24:05.398)
                        Oh they do, the dress that she&#39;s wearing is um... Jesus Christ. Marlene Dietrich I think?</p>
                        <p>Bryan! (01:24:13.292)
                        Oh, all right. But yeah, it&#39;s lit very severely at times. There&#39;s a lot of hard, hard shadows that in modern cinema would be like a faux pas. It would be bad form to shoot a movie like this, but it works. It&#39;s very deliberately set. And in a way that...</p>
                        <p>Dave! (01:24:33.01)
                        Well, it&#39;s natural to like if I, you know, if you were in a situation like that, where everything is just like, you know, lit by fucking Tiffany Hurricane lantern, you&#39;d also probably like by yourself, turn to the wall and just sort of like, be like, oh, I&#39;ve seen all fucking night shadows. I mean, of course, I&#39;m going to sit here and just do this dumb shit and play with this. Like it feels natural. That&#39;s why when it comes out of it, it&#39;s sort of like, whoa, that&#39;s cool as shit.</p>
                        <p>Bryan! (01:24:56.525)
                        Yeah. So while looking for her friends, Margaret comes under attack from a very drunk Torgo. I mean, Morgan. And she.</p>
                        <p>Dave! (01:25:05.87)
                        It&#39;s just. Well, so the thing about the one of the things that&#39;s really cool is that because I watched this three times and I&#39;m going to watch it again later because it&#39;s that fucking good. And when he&#39;s serving at the table, like it&#39;s clear that he is a an unwell person. And he has fixated on Gloria Stewart specifically.</p>
                        <p>Bryan! (01:25:15.792)
                        Thanks for watching!</p>
                        <p>Dave! (01:25:32.726)
                        And so he&#39;s going around the table, he&#39;s pouring water or whatever. He goes like, I think it&#39;s after. Well, she covers her hand and then he comes back later. And I think it&#39;s after the good time gals have shown up and they&#39;re all sitting around the table and he sort of like goes to pour water. He&#39;s pouring with his hand, but he has like in the Porterhouse&#39;s glass or whatever. He has his whole body turned towards her and it&#39;s super awkward.</p>
                        <p>Bryan! (01:25:38.072)
                        He goes to pour into hers and she like covers.</p>
                        <p>Yeah.</p>
                        <p>Dave! (01:26:01.086)
                        He&#39;s just like, I&#39;m pouring into this glass. He&#39;s like leaning towards and looking at her. It&#39;s so fucking creepy.</p>
                        <p>Bryan! (01:26:08.184)
                        Yeah, yeah, he&#39;s very fixated on her for the whole time. Which may play into the sort of haunted past of this house, like the dead sister who was very handsome as we as we keep hearing. Maybe he reminds her, she reminds him of that dead sister, you know, like that sort of thing. Long white neck.</p>
                        <p>Dave! (01:26:30.802)
                        With the long white neck, the big red lips and the big eyes, that does not sound sexy to me.</p>
                        <p>Bryan! (01:26:36.976)
                        I see you know he&#39;s have you ever seen Nosferatu? He&#39;s describing Isabella Johnny in that movie.</p>
                        <p>Dave! (01:26:39.571)
                        I have.</p>
                        <p>And so there&#39;s a lot of parallels between that movie and this. Just the way that everything is filmed, the way everything is shot, also made by a gay man.</p>
                        <p>Bryan! (01:26:46.488)
                        Yeah. Yep.</p>
                        <p>Yeah, yep. So she runs upstairs and finds Philip, and Morgan comes up and Philip tries to fight Morgan, and he punches him a bunch of times. And he&#39;s, Philip is wailing on him, and his punches are just bounce. He says, their lover&#39;s not. Yeah. Yep, he&#39;s a lover, not a fighter. I probably did, because it worked. It worked, he throws the lamp.</p>
                        <p>Dave! (01:27:02.6)
                        That&#39;s Frankenstein&#39;s monster. You can&#39;t fight that thing.</p>
                        <p>You&#39;re Canadian, Philip! You don&#39;t know how to do this! You threw a Tiffany lamp at him! Which, to be fair, is what I would do too.</p>
                        <p>Strut it.</p>
                        <p>Bryan! (01:27:19.632)
                        throws the lamp at him and it causes him to fall down the steps and he gets knocked out. So we cut back to the barn and this is where Penderel and Gladys are talking. What we find out is that she and Porterhouse are really just friends.</p>
                        <p>Dave! (01:27:35.458)
                        Well, so she is, I&#39;ve heard other people describe her as a sex worker, which is not true. She&#39;s a paid companion.</p>
                        <p>Bryan! (01:27:41.804)
                        No, because she says, she says very explicitly, and this is one of those Hays Code things that wouldn&#39;t have flown. He doesn&#39;t expect anything from me. He just, he pays for her expenses and they hang out. They&#39;re just friends. He kind of takes care of the bill and pays the rent. And she says very explicitly, he expects nothing in return. And there&#39;s a...</p>
                        <p>Dave! (01:27:47.818)
                        He doesn&#39;t expect anything.</p>
                        <p>And this is where she says he likes people to think he&#39;s ever so gay.</p>
                        <p>Bryan! (01:28:08.76)
                        That&#39;s right. That&#39;s right.</p>
                        <p>Dave! (01:28:10.594)
                        And so this is that double entendre again, where it&#39;s just like gay men in the audience. We&#39;ve been like, I get what you&#39;re doing there. I see you. I see. But then so they have this conversation where basically she&#39;s just like, I just hang out with the guy and.</p>
                        <p>Bryan! (01:28:17.588)
                        Yeah.</p>
                        <p>Yeah, because like what we learn is he&#39;s really he&#39;s just a lonely guy</p>
                        <p>Dave! (01:28:29.11)
                        Well, so then Penderel says something. I think he says, I wrote it down. It says he&#39;s still in love with that little dead wife. And I wonder if that line, if they made him put that line in because everything she is implying is that he is gay.</p>
                        <p>Bryan! (01:28:38.52)
                        Yeah.</p>
                        <p>Yeah, yeah, like that&#39;s.</p>
                        <p>Dave! (01:28:48.658)
                        Now, and that was very, very common at the time. I mean, fucking 60 percent of the men in Hollywood at this time were gay men married to women because they had to be saying it lasted that way until the 60s.</p>
                        <p>Bryan! (01:28:56.856)
                        Yeah. Well, right. Like, right. Like, I think Fessager was married to a woman for a while also. Watten was.</p>
                        <p>Dave! (01:29:02.826)
                        St. George was Charles Lawton was the only James well wasn&#39;t, which was obviously a problem. But like this, I wonder if they made him put that in just to be like, we can&#39;t you can&#39;t be that obvious about it. But like everything else about this character, because the story doesn&#39;t make sense. It feels very real. It&#39;s like, oh, she wore a cotton dress and then she died. It&#39;s like, what the fuck are you talking about?</p>
                        <p>Bryan! (01:29:10.125)
                        Ha ha.</p>
                        <p>Maybe that could have been a that could have been a studio note</p>
                        <p>Bryan! (01:29:28.468)
                        Right, because really the crux of his character, the primary motivator is he&#39;s an empty vessel because he spent all of his time chasing money. It even says, like, and like, once you&#39;re rich, like you can&#39;t be rich enough and you know, it&#39;s all I do. And really like his unhappiness is the fact that like his wealth has kind of, yeah, his wealth has kind of isolated him.</p>
                        <p>Dave! (01:29:49.342)
                        He&#39;s lonely. He&#39;s extremely lonely.</p>
                        <p>And there&#39;s like no other explanation for like, oh, so you just pay her to hang out with you. But like, why would you not, if you&#39;re just going to hang out with her, why would you not also do other things with her? Talking about doing sex with her. Like what, like it just doesn&#39;t. And then they like, oh, that&#39;s that story about the wife feels really shoehorned in there. Where it just feels like this is not, this whole movie is fucking beat for beat perfect.</p>
                        <p>Bryan! (01:30:03.577)
                        Yeah.</p>
                        <p>Yeah.</p>
                        <p>Bryan! (01:30:15.247)
                        Right.</p>
                        <p>Dave! (01:30:20.022)
                        The only thing that doesn&#39;t feel right is that story where it&#39;s just like, this feels like a total afterthought. And I imagine they got to it and they were like, this is you&#39;re being too explicit.</p>
                        <p>Bryan! (01:30:23.503)
                        Yeah.</p>
                        <p>Yeah.</p>
                        <p>Dave! (01:30:31.702)
                        Because he also reads that way, like on camera, he reads that way. Because he&#39;s supposed to be that sort of like, and taught, he&#39;s supposed to be the opposite of Melvin Douglas, who was this sort of strapping, you know, handsome man. He&#39;s this kind of like, almost humorous character. But even that, they play with like a lot of, sort of compassion, where Melvin Douglas is like, no dude, I&#39;m fine with whatever you do. Like, I don&#39;t give a shit. It&#39;s just not for me.</p>
                        <p>Bryan! (01:30:45.87)
                        Mm-hmm.</p>
                        <p>Yeah.</p>
                        <p>Bryan! (01:30:55.36)
                        Well, right, because here&#39;s the thing. What happens next after this scene really kind of jives with your thesis here. Cause, so.</p>
                        <p>Dave! (01:31:06.726)
                        Exactly. Because every single gay man I&#39;ve ever known and the one that I have been for the 100 years has had this situation.</p>
                        <p>Bryan! (01:31:16.872)
                        Yeah. So they have this weird way of kind of professing their love for one another. It&#39;s this very bizarre thing where they&#39;re like, what if we pretended that we were in love? And what if you...</p>
                        <p>Dave! (01:31:30.114)
                        So apparently, apparently Gloristort was like, well, I never liked this scene because I just don&#39;t see it. I don&#39;t, I think that she should have been with Porterhouse at the end. And I was sort of like, Gloria, I think you might&#39;ve missed something along the way here. But apparently audiences also hated their relationship because it&#39;s like, she&#39;s like, what if we were in love? It&#39;s like, bitch, you have known him for five minutes. You have literally been there for two hours.</p>
                        <p>Bryan! (01:31:44.192)
                        She&#39;s beat.</p>
                        <p>Right, okay. So they do this and then they kind of, they have this sort of like loving embrace and then she&#39;s like, all right, we gotta go back to the house and tell Porterhouse what we&#39;re gonna do. And he&#39;s like, all right, I got something. She&#39;s like, what if we live together? And he&#39;s like, that sounds great. Hey, I&#39;ve got an improvement on that. She&#39;s like, yeah, what&#39;s that? And he&#39;s like, I&#39;ll tell you later. And so they go.</p>
                        <p>Dave! (01:32:13.482)
                        It&#39;s real. But it&#39;s like it&#39;s cute. It&#39;s charming. It&#39;s it&#39;s like it was a Zoe Deschanel. It&#39;s like Zoe Deschanel, but way better. It&#39;s just like, what if I&#39;m cute and like we&#39;re all cute about it?</p>
                        <p>Bryan! (01:32:16.331)
                        Yeah.</p>
                        <p>Oh my God, yeah. Lillian Bond, Lillian Bond is the proto, what did they call that? Manic Pixie Dream Girl? Yep.</p>
                        <p>Dave! (01:32:30.398)
                        A manic pixie dream girl. Like, yeah, she&#39;s that, except I don&#39;t want to push her in front of a truck.</p>
                        <p>Bryan! (01:32:38.557)
                        So they he carries her back to the house And they this is where they&#39;re gonna tell Porter house what their what their plan is but again more of her feet are in this shot So they tell Porter house like</p>
                        <p>Dave! (01:32:49.546)
                        Yeah, they&#39;re... Well, so they walk into the room and they&#39;re like, Whoa, what the fuck happened in here? Because the room is a wreck, because Morgan went on a tear and wrecked the room. But Porterhouse is just sort of like, Oh yeah, I came back and nobody was around, so I thought I&#39;d take a nap. It&#39;s like, dude, this room looks like people were killed in here and you just took a nap?</p>
                        <p>Bryan! (01:32:57.118)
                        Right? Because... Yeah, because...</p>
                        <p>He overturns the table.</p>
                        <p>Dave! (01:33:15.126)
                        But he gets up, he&#39;s sort of sloppy as he gets up and goes to the door and lets them in.</p>
                        <p>Bryan! (01:33:15.23)
                        He, uh...</p>
                        <p>Yeah, he&#39;s a little he&#39;s like he&#39;s it&#39;s like he&#39;s drunk. Oh And she says the weirdest thing is cuz So so penderel is like washing her feet and she looks at Porter house and she&#39;s like what I got my feet wet and that was</p>
                        <p>Dave! (01:33:25.719)
                        Yeah she does.</p>
                        <p>No, he says he says your feet are wet. She was like, that&#39;s not the only thing. Damn.</p>
                        <p>Bryan! (01:33:38.72)
                        And that&#39;s not a, yeah, she goes, and that wasn&#39;t all. And I&#39;m like, and I went, am I being gross right now? Like, that&#39;s such a fucking strange line. It&#39;s clever. Yep. Yep, my note is, what? But she, so they announced to Porterhouse, oh, hey, like, look, we&#39;re in love and we&#39;re gonna like live together and shit. And he&#39;s like,</p>
                        <p>Dave! (01:33:44.866)
                        No, no, no</p>
                        <p>Yeah.</p>
                        <p>Bryan! (01:34:09.016)
                        That&#39;s cool, I guess.</p>
                        <p>Dave! (01:34:10.038)
                        Yeah, he&#39;s basically just like, I get it. I see. I saw it when I got here. I understand. You guys, you&#39;re real cute together. I get it. You know what? I&#39;m going to get you something nice.</p>
                        <p>Bryan! (01:34:14.589)
                        Yeah.</p>
                        <p>Yep. Yeah. He&#39;s like, and then so then she walks off, and he&#39;s like, hey, listen, Porterhouse, I haven&#39;t told her yet. I&#39;m going to tell her the cold light of the morning or something like that. It&#39;s a line that they keep saying over and over and over, like right up until the end. I intend to like, I&#39;m going to marry her when you come to the wedding. And he&#39;s like, I think you&#39;re fucking crazy. But yeah, I&#39;ll be there.</p>
                        <p>Dave! (01:34:40.01)
                        Yeah, I love it. He&#39;s just like, yeah, dude, of course I&#39;ll be there. I&#39;m your fucking I&#39;m your bro. Of course I&#39;m going to do this with you.</p>
                        <p>Bryan! (01:34:43.308)
                        Yeah, and then he&#39;s like, Hey, yeah. Hey, let&#39;s say we fix this room. And then they turn the table back over and Porterhouse announces, that&#39;s no way to treat a roast beef, which I strongly agree with.</p>
                        <p>Hahaha</p>
                        <p>Dave! (01:34:59.887)
                        Look, this night is gone sideways. There&#39;s no reason we can&#39;t save the roast beef.</p>
                        <p>Bryan! (01:35:07.821)
                        Yep.</p>
                        <p>Dave! (01:35:08.3)
                        No one expected any of this. But we shouldn&#39;t go hungry.</p>
                        <p>Bryan! (01:35:10.324)
                        No. So we cut back to Margaret and Philip who go upstairs and this might be my favorite scene in the entire movie.</p>
                        <p>Dave! (01:35:18.958)
                        This is like, we have two fucking wildly divergent storylines where one is just like, Hey, you mind if I marry your hag? Ah, no, it&#39;s totally cool, man. Like, look, we&#39;ve had good times, but I get it. I can&#39;t give her what she wants. Meanwhile, upstairs, fucking Nancy Drew and the Canadian are tearing the place apart with the giantest keys you&#39;ve ever seen.</p>
                        <p>Bryan! (01:35:26.832)
                        Hahaha</p>
                        <p>Yeah, they walk into, yeah, they go, they are huge. Oh my God, I&#39;m glad it wasn&#39;t just me who noticed that. So they go to Sir Roderick&#39;s room. We&#39;ve not seen Sir Roderick. We&#39;ve only heard him laughing maniacally in the background occasionally. What they find is this ancient man in a bed.</p>
                        <p>who immediately is like, who&#39;s, who says who&#39;s there? But here&#39;s the thing. What appears to be, I mean, it&#39;s obviously, it&#39;s a person wearing a shit load of makeup, but this is a woman&#39;s voice.</p>
                        <p>Dave! (01:36:10.08)
                        Also, they did not see this. They didn&#39;t see the character at all until this scene, until they shot the scene. So they walked in totally cold to this.</p>
                        <p>Bryan! (01:36:15.198)
                        Ah.</p>
                        <p>Well done, well done, because it really fucking works. This is such a strange scene, and it&#39;s really like.</p>
                        <p>Dave! (01:36:23.326)
                        I think this scene is amazing and it&#39;s incredibly subversive.</p>
                        <p>Bryan! (01:36:26.496)
                        Yeah, so this appears to be a very, very old man lying in a bed with like wispy white hair and a beard, but it&#39;s not. It&#39;s an actress named Elspeth Duggin in a wig and a beard, and she&#39;s actually credited as John Duggin in the opening titles for some reason. This is, I think so too, this is like Twin Peaks level of weirdness.</p>
                        <p>Dave! (01:36:49.311)
                        I think they just didn&#39;t want to blow the illusion.</p>
                        <p>Yeah, this, I mean, this, you know, call back to, what&#39;s your face there? What&#39;s the one, Carrie? I might&#39;ve known it&#39;d be red.</p>
                        <p>Bryan! (01:36:56.595)
                        Um...</p>
                        <p>Oh, Piper Laurie.</p>
                        <p>Dave! (01:37:07.902)
                        Yeah, which does that really offensive Mr. Moshimoto or whatever. It&#39;s very much that.</p>
                        <p>Bryan! (01:37:12.624)
                        Yeah, yeah. Dirty. Yeah. So, at no point are we supposed to think that she&#39;s really a woman. It&#39;s not like she&#39;s rea- maybe she is. Like, I don&#39;t know. This whole scene is just so fucking bizarre. I think what he wanted was the voice coming out of an old man, really.</p>
                        <p>Dave! (01:37:29.166)
                        I don&#39;t think you are. I think what he&#39;s doing though is...</p>
                        <p>So there is a comment somewhere in there about the pipes and the whistle or something like that, about it&#39;s sort of a reversion. As you age, as a man, as you age, you sort of become this feeble thing again. And the only way he could really achieve that, without it looking cheesy and dumb, he really wanted decrepit vulnerability, is to put a woman in there.</p>
                        <p>Bryan! (01:37:52.588)
                        Yeah, yeah.</p>
                        <p>Yeah.</p>
                        <p>Dave! (01:38:03.43)
                        And but it&#39;s very clearly a woman like there&#39;s no I mean, I don&#39;t know what it would have looked like, you know, on us on a big screen. But like. But even without it, like, it&#39;s still like, OK, it&#39;s pretty clear, but like there&#39;s something about this, like reversion back to fragility that is really interesting.</p>
                        <p>Bryan! (01:38:11.009)
                        In 4K it really... it really...</p>
                        <p>I mean, but also it&#39;s just a nonsense scene. Well, it&#39;s a nonsense casting decision that has this like oversized effect on the entire movie. It&#39;s weird in the way that like David Lynch just throws random weirdness into his movies to kind of keep you off balance. Like it&#39;s...</p>
                        <p>Dave! (01:38:44.446)
                        And she was also someone who had worked with him in other things too. Because he used the same people all the time.</p>
                        <p>Bryan! (01:38:47.504)
                        Right, right. So, right. So this is a scene that really what the scene is, is this is a big exposition dump about the family. But what she does first is tells this basically the same story that Rebecca tells Margaret at the beginning, but from a wildly different perspective where Rebecca was extremely judgmental towards the others in the family.</p>
                        <p>Dave! (01:39:11.946)
                        Yeah, it&#39;s got a real, ugh, this bitch kind of vibe to it.</p>
                        <p>Bryan! (01:39:15.236)
                        Yeah, like this is this is the person who she was being judgmental of and she&#39;s like we used to have very we used to have big parties and everybody used to come and hang out and then there was this very terrible like this very terrible thing that happened. It&#39;s not explicit. It&#39;s never explained. It&#39;s just implied very heavily. Yep.</p>
                        <p>Dave! (01:39:31.874)
                        But that&#39;s that thing again of secrets. Secrets and illusion. We&#39;re not gonna tell you, it doesn&#39;t matter what actually happened. It was a bad thing that happened and we cannot talk about it.</p>
                        <p>Bryan! (01:39:41.84)
                        Yep. She says, oh, but everybody has a touch of crazy in this family, except for me. I think that&#39;s what it is, yeah. Yep. Yeah, when you&#39;re sexy, you ride horses and then you fall because you&#39;re distracted by all the dudes. Ooh, damn, damn. Yeah. Okay.</p>
                        <p>Dave! (01:39:47.434)
                        It&#39;s just like she falls off a horse, right?</p>
                        <p>Because she&#39;s out being sexy or whatever. Because that&#39;s what happens.</p>
                        <p>Dave! (01:40:00.974)
                        Christopher Reeves knows. I don&#39;t feel good about that. I don&#39;t think I like that. Cut that out, I don&#39;t like that.</p>
                        <p>Bryan! (01:40:10.168)
                        She tells them about Morgan and let&#39;s know that he&#39;s a savage brute Which I think it makes her the third fem to make that remark. I Know I know I know I know it&#39;s supposed to be a man. I but the I cannot I don&#39;t know</p>
                        <p>Dave! (01:40:15.99)
                        But but also you keep saying she which is.</p>
                        <p>Um...</p>
                        <p>But there&#39;s an implication somewhere in here and I can&#39;t spot it. I just know that it like I&#39;ve read a bunch of stuff and to know that it&#39;s there. There&#39;s an implication that Morgan is a part of their family in some way.</p>
                        <p>Bryan! (01:40:38.188)
                        Right, because okay, so when Rebecca tells the story, she says, I had a sister who died. Now when Sir Roderick tells the story, he says, I have two children who were killed, or who died. And so we never, he mentions one of them, Saul, who we&#39;ll meet very soon, but...</p>
                        <p>never mentions the other one and what I think is that Morgan because Morgan&#39;s face is all jacked up it&#39;s scarred and shit so he he&#39;s yeah he&#39;s very he&#39;s been injured I think the implication here is that Morgan is the other child who in a sense has died.</p>
                        <p>Dave! (01:41:14.134)
                        Yeah. In a real puttied kind of way.</p>
                        <p>Bryan! (01:41:30.744)
                        But I can&#39;t really be sure. But this is the thing that earlier when I said, like the family&#39;s last name kind of comes into play, fem. We have a woman playing a man in this scene.</p>
                        <p>Dave! (01:41:41.27)
                        And I think that&#39;s, that&#39;s again, it&#39;s that sort of like, it&#39;s that double entendre again of where it&#39;s like, hey, if you know, you know, right?</p>
                        <p>Bryan! (01:41:48.44)
                        Yeah, yeah. So this is where we find out about Saul, who is the other Fem child who has to be locked up. He&#39;s the one who&#39;s</p>
                        <p>Dave! (01:41:57.758)
                        He&#39;s the leprechaun giggling in the attic.</p>
                        <p>Bryan! (01:41:59.988)
                        No, no, Sir Roderick is the giggling one. Because you hear him, you hear him make, you hear him laugh like that when they go in. And then eventually, but what he keeps saying is, he has to be locked up because he&#39;s nuts and he&#39;s always trying to set the house on fire. And the thing is, Morgan is in charge of him. And he sometimes lets them out and just mayhem ensues until they manage to get him back into the room.</p>
                        <p>Dave! (01:42:03.662)
                        because there&#39;s more than this.</p>
                        <p>Bryan! (01:42:28.588)
                        And then Sir Roderick falls asleep, and that&#39;s kind of the end of his thing.</p>
                        <p>Dave! (01:42:33.489)
                        Yeah, so like, so Roger, we&#39;re gonna lock you in here with these gigantic comical keys.</p>
                        <p>Bryan! (01:42:38.348)
                        Yep, and so Philip runs out and finds that Morgan is gone, and Horace tells him that he ran up to let Saul out and that he must kill him. And then he ducks back into his room. And then once they&#39;re all downstairs, they hear Morgan coming down the steps. And this is another one of my favorite scenes in the movie because it&#39;s serious. It&#39;s, yes, you&#39;re right, it&#39;s super spooky. So...</p>
                        <p>Dave! (01:42:57.06)
                        This is also another illusion scene.</p>
                        <p>Bryan! (01:43:03.816)
                        What happens is we hear him coming down the steps, but we don&#39;t see him. At first, all we see is an arm kind of sliding down the railing. And then Morgan emerges from behind the wall, like coming down the steps. And you think it&#39;s his arm, but he keeps fucking walking and the arm just stays in place. It&#39;s, it&#39;s, it&#39;s very uncanny.</p>
                        <p>Dave! (01:43:18.718)
                        And it looks it&#39;s so jarring because it&#39;s like it almost looks right, but it&#39;s not right.</p>
                        <p>Bryan! (01:43:27.913)
                        It&#39;s disembodied and you cannot see whose arm it is. It&#39;s just so strange looking. And so Morgan comes down and he lurches at the women and the men kind of struggle to kind of keep him under control. And Rebecca&#39;s like.</p>
                        <p>Dave! (01:43:43.538)
                        If any, if this movie drags anywhere, it&#39;s right here in this moment with this like language fight.</p>
                        <p>Bryan! (01:43:48.084)
                        Yeah, it takes forever to get him into this. They&#39;re trying to get it. Rebecca&#39;s like get him into the kitchen lock him in the kitchen It takes broken forever Yeah And while this is happening we hear all that we hear that same like wild laughter from upstairs and so They&#39;ve got Morgan in the kitchen the door to the main hall closes behind penderel and the two women</p>
                        <p>Dave! (01:43:54.594)
                        Like take him down that Resident Evil hallway and get him into the kitchen.</p>
                        <p>Bryan! (01:44:17.824)
                        And so, Penderel hides the girls in a cupboard, and then he deals with Saul, who comes around, who eventually comes around the corner, and we find out his...</p>
                        <p>Dave! (01:44:24.01)
                        Yeah, you&#39;re waiting to like you&#39;re waiting for this. You&#39;re waiting for Frankenstein&#39;s monster to come down the stairs. Nope. You get Warwick Davis leprechaun.</p>
                        <p>Bryan! (01:44:28.272)
                        this like, yeah, and he&#39;s just this, he&#39;s just this, he&#39;s just this little man. And at first he&#39;s like, please don&#39;t hurt me. He&#39;s very frail, but like as this interaction goes on, he gets crazier and crazier and crazier.</p>
                        <p>Dave! (01:44:47.034)
                        They&#39;re going to tell you I&#39;m crazy. They lock me away because I know that they murdered my sister.</p>
                        <p>Bryan! (01:44:51.756)
                        Yeah, so he holds the secret. He has this, he has the, he knows, he has the knowledge that they&#39;ve, that they&#39;re locking away. He is literally a person that they&#39;re, they&#39;re keeping, like they have to keep locked up in order to keep this secret.</p>
                        <p>Dave! (01:45:02.706)
                        Alas, he cannot keep the illusion together very long.</p>
                        <p>Bryan! (01:45:05.824)
                        No, he goes fucking nuts. And so a penderel like seats him at the far end of one at one of the end of the table and he goes and he sits at the other one they start to talk and he&#39;s trying to distract him because he&#39;s like, because Saul keeps going like, who&#39;s that in the cupboard. He&#39;s like,</p>
                        <p>Dave! (01:45:19.65)
                        Yeah, this whole this whole conversation is I know, you know, I know. That&#39;s all this is. And it goes on and on and every single time he&#39;s just like, who&#39;s in the cupboard there? He&#39;s like, no, no. Tell me. Tell me more. Tell me more about your you&#39;re saying something about fire for a minute there.</p>
                        <p>Bryan! (01:45:23.694)
                        Yeah.</p>
                        <p>He&#39;s like, don&#39;t worry about that. Yeah.</p>
                        <p>Bryan! (01:45:36.224)
                        Yeah, he does that really cool thing where he gets up and he scoots around the table like really quick and sits in the chair next to penderel and they start to have. he&#39;s got the crazy knife and he&#39;s gesturing he keeps talking now this guy&#39;s like a pyromaniac he&#39;s very much into fire he keeps gesturing wildly with the knife.</p>
                        <p>Dave! (01:45:43.93)
                        Meanwhile, he has that giant bread knife in his hand.</p>
                        <p>Yeah, you know how like normal people talk to you and wave a knife in your face? It&#39;s like that.</p>
                        <p>Bryan! (01:45:59.04)
                        Yeah. And eventually he&#39;s just not having it. Penderel keeps, Penderel&#39;s trying to get a fire poker to just have something to defend himself with. And eventually Saul has had enough of this and he fucking throws the knife like a dart, it flies across the room and it sticks into the chair, like right next to Penderel&#39;s head. And they don&#39;t,</p>
                        <p>Dave! (01:46:16.234)
                        And he goes at him like that monkey guy from Bloodsport. He is like a wild animal.</p>
                        <p>Bryan! (01:46:19.8)
                        Yes, he does! He comes at him like a spider monkey! So yeah, they have this big fight, and he eventually knocks out Pendril, and then he runs up the steps laughing wildly, and he grabs a burning log from the fireplace. He runs up and he attempts to set the curtains on fire. Pendril comes-</p>
                        <p>Dave! (01:46:41.61)
                        And it&#39;s weird, you can never take any of this too seriously because of the way he&#39;s behaving. It&#39;s very much like, it&#39;s like if the Lucky from the Lucky Charms commercial was trying to set your house on fire. He&#39;s giggling wildly and he&#39;s just like, oh, it&#39;ll be great when I do this. You guys are gonna love it.</p>
                        <p>Bryan! (01:46:54.584)
                        He&#39;s a man.</p>
                        <p>Yep. Pen roll wakes up. He runs up the steps and they struggle over the railing and you can see it coming like the railing like cracks and then they both fall over and the and the fall appears to kill them both.</p>
                        <p>Dave! (01:47:14.594)
                        But I mean, that the fall is loud as fuck, especially if you take into consideration you in Dracula, which is a year earlier, they made them cut out the scene where Dwight Fry gets killed at the end because he falls from the stairs. This scene is easily worse than that. Like they fall from the next floor and you hear some loud crunches.</p>
                        <p>Bryan! (01:47:27.513)
                        Yeah.</p>
                        <p>Yeah. So Morgan breaks out of the kitchen. He literally breaks the door down. And he frees the ladies from the cupboard and immediately starts to menace them.</p>
                        <p>And there&#39;s a lot of like, get away from me, you brute. And like that sort of thing. And he knocks Gladys out. And then he goes for Margaret, who&#39;s basically the object of his like adoration. Like he&#39;s in love with this girl because, probably because she reminds him of his sister, perhaps. But then she points out that Saul is dead and he basically stops his assault on her.</p>
                        <p>turns and like his face drops. It&#39;s a very expressive scene like Karloff is the fucking man because he really speaks volumes with this.</p>
                        <p>Dave! (01:48:27.242)
                        And this is that final moment of illusion. It&#39;s, he&#39;s not what you think. He&#39;s wild and crazy, but he&#39;s something else as well.</p>
                        <p>Bryan! (01:48:32.076)
                        Yeah, so he&#39;d go...</p>
                        <p>Yeah, he goes over and he picks up Saul and he&#39;s dead. And what he does is he just carries him back upstairs. And that&#39;s the last we see of Morgan. And then Gladys.</p>
                        <p>Dave! (01:48:45.738)
                        But he&#39;s so distraught by this death. And that sort of, again, it feeds into that idea of like, is he, is this his father?</p>
                        <p>Bryan! (01:48:53.568)
                        Yeah, it&#39;s, it&#39;s super.</p>
                        <p>Dave! (01:48:55.062)
                        Like he&#39;s an illegitimate child, sort of what&#39;s implied throughout the movie, very, very subtly, and it&#39;s sort of reinforced a little bit at the end.</p>
                        <p>Bryan! (01:49:01.764)
                        Oh Jesus Christ, the implications of that are so fucked up. So what I am thinking is that he is basically the incest offspring of this dead sister and Saul.</p>
                        <p>Dave! (01:49:13.678)
                        Yes.</p>
                        <p>That&#39;s sort of what&#39;s implied in that story that Roderick tells them. But it&#39;s nothing&#39;s ever really said. But like, that&#39;s why he&#39;s kind of fucked up. That&#39;s why he doesn&#39;t talk.</p>
                        <p>Bryan! (01:49:20.588)
                        Yeah. Gah!</p>
                        <p>That&#39;s the thing. It&#39;s perfect Gothic fiction. So he carries him upstairs very sadly, and that&#39;s the last he sees him. And then Gladys comes back to get her senses, and she goes over. We find out that Penderel is still alive. He&#39;s just very badly hurt.</p>
                        <p>Dave! (01:49:29.998)
                        Yeah.</p>
                        <p>And we do the he&#39;s alive, he&#39;s alive. Which is I it had to be a conscious choice.</p>
                        <p>Bryan! (01:49:46.284)
                        Yeah. Oh, yeah.</p>
                        <p>Yeah, yeah. Hey, remember that movie I made you liked that, huh?</p>
                        <p>Dave! (01:49:54.454)
                        Yeah, 20 minutes ago. Because it&#39;s 1932, we make one of these every six months.</p>
                        <p>Bryan! (01:50:01.368)
                        This movie was made front to back in two months. Yeah, so I know, I know. But in the morning, Margaret and Philip go out to get an ambulance for Penderel and then Penderel proposes to Glett.</p>
                        <p>Dave! (01:50:06.159)
                        Only Roger Corman could do better.</p>
                        <p>Well, they all kind of, they wake up and it&#39;s just sort of like, everyone&#39;s just like, Ah, ugh, it&#39;s a wild party, wasn&#39;t it?</p>
                        <p>Bryan! (01:50:21.088)
                        Yeah, that was quite a quite a quite a night. Yeah. So they want to get an ambulance and then penderel proposes to Gladys in the cold morning of the light or whatever that phrase is he keeps saying. I don&#39;t think she the cold light of day. That&#39;s what he says. And I don&#39;t think she accepts but like, oh, she does. I couldn&#39;t remember.</p>
                        <p>Dave! (01:50:33.486)
                        Cold light of day.</p>
                        <p>No, she does. Of course she does, because in my note says not even 24 hours have they known each other.</p>
                        <p>Bryan! (01:50:48.721)
                        Yeah, and then we fade it</p>
                        <p>Dave! (01:50:50.166)
                        But look, you never knew when you were gonna die. You might end up at this weird house in the fucking Welsh countryside.</p>
                        <p>Bryan! (01:50:55.648)
                        After that, I would probably start to really, really reflect on my priorities for sure. Yep. And so, yep. So then, that&#39;s it. End of movie. We fade to the title card.</p>
                        <p>Dave! (01:51:00.726)
                        Yeah, clock&#39;s ticking. You either marry Gladys now or you get the fuck out.</p>
                        <p>Well, because then Horus comes down, he&#39;s just like, Alright, bye, get the fuck out! It has this weird, like, ending that is, you&#39;d think they would&#39;ve just, like, fade to black as they drive away, but no. Horus comes out, he&#39;s just like, Well, thanks for coming. Like, they&#39;re sort of like, This thing happens all the time, I guess.</p>
                        <p>Bryan! (01:51:10.816)
                        Yeah. Yup.</p>
                        <p>Ha ha!</p>
                        <p>Bryan! (01:51:28.285)
                        In a way, like, I suppose he should be relieved, because, like, these people came in and resolved a real problem for him. Yeah, so, uh...</p>
                        <p>Dave! (01:51:33.71)
                        Well, you solved my problem. I hate all these people. If only you had killed the other one too.</p>
                        <p>Bryan! (01:51:39.5)
                        Okay, hey before you guys leave what do you think of my sister? Yep, so we fade out to the universal title card. It says it&#39;s a universal picture Yep, so there it is there it is the old dark house fucking amazing And honestly like I really enjoyed it so like usual watch it twice watch it to take it in and then I do a notes version but like</p>
                        <p>Dave! (01:51:48.847)
                        Ah, this movie, god damn.</p>
                        <p>Bryan! (01:52:06.608)
                        I didn&#39;t think that I could like it any more than I did. And just hashing it out like this, I&#39;ve really kind of like found an even deeper appreciation for it. Like</p>
                        <p>Dave! (01:52:14.519)
                        Because you see everything he does and everything that they&#39;re all doing. Like this is a movie that he very consciously makes, you know, for the studio. He makes it for an audience. But at the same time, you can see how a gay audience would sit down and watch the movie and be like, they would get a totally different story and it would be fucking hilarious. In that same way that</p>
                        <p>Bryan! (01:52:18.457)
                        Yep.</p>
                        <p>Yep.</p>
                        <p>Dave! (01:52:37.15)
                        you know, gay men sat down and watched Mommy Dearest and came away with something very different than what was the studio thought they were giving them. It&#39;s that except he knows what he&#39;s given. It&#39;s it&#39;s every there is not a fucking second wasted, except maybe when they&#39;re clamoring down the hallway. Everything is just like beat for beat, fucking perfect.</p>
                        <p>Bryan! (01:52:43.091)
                        Hahaha</p>
                        <p>Yeah.</p>
                        <p>Bryan! (01:52:55.335)
                        Yeah, yeah.</p>
                        <p>Bryan! (01:52:59.224)
                        Yeah, honestly, like if it&#39;s really given me like a different sort of perspective on his work like Where where Bride of Frankenstein is like my favorite one. It&#39;s very that was very much just a job for him This was like this was like probably I would consider this like his magnum opus like this. He really</p>
                        <p>Dave! (01:53:16.474)
                        say Frankenstein was the job for him. Bride he had a lot more creative control over. Because that one feels a lot queerer to me. And so does Invisible Man for that matter. Invisible Man is my favorite of them. Yeah.</p>
                        <p>Bryan! (01:53:21.6)
                        Yeah, but.</p>
                        <p>Oh yeah.</p>
                        <p>Bryan! (01:53:29.74)
                        No shit. All right, cool, cool. All right, what are we doing next?</p>
                        <p>Dave! (01:53:34.386)
                        Oh, well, we&#39;re getting back some trash. We&#39;re going to summer camp to see. We&#39;re going to watch Madman and Madman is. I don&#39;t even know how it is a spectacular film. It can be found just about everywhere. There&#39;s a great Vinegar Syndrome 4K edition of it.</p>
                        <p>Bryan! (01:53:37.504)
                        Yep. Go to summer camp. Oh, yes.</p>
                        <p>It&#39;s a speck. It&#39;s a very special thing.</p>
                        <p>Bryan! (01:53:55.468)
                        Yep, I think it&#39;s streaming on shutter still. I think that&#39;s where I saw it most recently.</p>
                        <p>Dave! (01:54:00.747)
                        It&#39;s probably on Tubi, where all great things are.</p>
                        <p>Bryan! (01:54:05.504)
                        Yep. All right. So that&#39;s it.</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/vamp">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)